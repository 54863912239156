import { ButtonLight, ButtonPrimary } from 'components/Button'
import { AutoColumn, ColumnCenter } from 'components/Column'
import Modal from 'components/Modal'
import { RowBetween } from 'components/Row'
import { CloseIcon } from 'nft/components/icons'
import { useContext } from 'react'
import { AlertTriangle, CheckCircle } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { CustomLightSpinner, ExternalLink } from 'theme'

import Circle from '../../../assets/images/blue-loader.svg'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.deprecated_bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`

const BottomButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
`

interface TransactionConfirmModalProps {
  title?: string
  success: boolean
  loading: boolean
  onDismiss: () => void
  heading: string
  subHeading: string
  href: string
  isFinalStep?: boolean
  onSuccessCallBack?: () => void
}
export function ConfirmationPendingContent({
  onDismiss,
  loading,
  subHeading,
  title = 'Modal Title',
  heading = 'Modal Heading',
  href,
  success,
  isFinalStep = false,
  onSuccessCallBack,
}: TransactionConfirmModalProps) {
  const visibleFooterButton = !loading && success && isFinalStep
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            {title}
          </Text>
          {!visibleFooterButton && <CloseIcon onClick={onDismiss} width={'30px'} height={'30px'} />}
        </RowBetween>
        <ConfirmedIcon>
          {loading ? (
            <CustomLightSpinner src={Circle} alt="loader" size={'70px'} />
          ) : success ? (
            <CheckCircle size={'90px'} />
          ) : (
            <AlertTriangle color={theme.deprecated_red1} style={{ strokeWidth: 1.5 }} size={64} />
          )}
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            {heading}
          </Text>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={600} fontSize={14} color="" textAlign="center">
              {subHeading}
            </Text>
          </AutoColumn>
          {href ? <ExternalLink href={href}>View in Explorer</ExternalLink> : null}
        </AutoColumn>
      </Section>
      {visibleFooterButton ? (
        <BottomSection gap="12px">
          <BottomButtonWrapper>
            <ButtonPrimary onClick={onSuccessCallBack}>Done</ButtonPrimary>
          </BottomButtonWrapper>
        </BottomSection>
      ) : null}
    </Wrapper>
  )
}

interface TransactionErrorModalProps {
  title?: string
  message?: string | boolean
  reasonMessage?: string
  onDismiss: () => void
  onProceed?: () => void
  viewProceedButton?: boolean
}
export function TransactionErrorContent({
  message,
  onDismiss,
  title,
  reasonMessage,
  onProceed,
  viewProceedButton,
}: TransactionErrorModalProps) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            {`Error - ${title}`}
          </Text>
          <CloseIcon onClick={onDismiss} width={'30px'} height={'30px'} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.deprecated_red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text
            fontWeight={500}
            fontSize={16}
            color={theme.deprecated_red1}
            style={{ textAlign: 'center', width: '85%' }}
          >
            {message}
          </Text>
          <Text
            fontWeight={500}
            fontSize={16}
            color={theme.deprecated_red1}
            style={{ textAlign: 'center', width: '85%' }}
          >
            {reasonMessage === 'Error' ? '' : reasonMessage}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <BottomButtonWrapper>
          <ButtonLight onClick={onDismiss}>Dismiss</ButtonLight>
          {viewProceedButton ? <ButtonPrimary onClick={onProceed}>Proceed</ButtonPrimary> : null}
        </BottomButtonWrapper>
      </BottomSection>
    </Wrapper>
  )
}

interface TransactionModalProps {
  isOpen: boolean
  onDismiss: () => void
  error?: boolean
  success?: boolean
  loading?: boolean
  title?: string
  subHeading?: string
  heading?: string
  errorMessage?: string
  href?: string
  onProceed?: () => void
  viewProceedButton?: boolean
  isFinalStep?: boolean
  onSuccessCallBack?: () => void
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  error,
  loading = false,
  title = 'Modal Title',
  subHeading = 'Modal Sub Heading',
  heading = 'Modal Heading',
  success = false,
  href = '',
  onProceed,
  viewProceedButton,
  isFinalStep = false,
  onSuccessCallBack,
}: TransactionModalProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal isOpen={isOpen} onDismiss={!loading ? onDismiss : () => {}} maxHeight={90}>
      {error || heading === 'Error' ? (
        <TransactionErrorContent
          message={error || subHeading}
          onDismiss={onDismiss}
          reasonMessage={heading}
          onProceed={onProceed}
          viewProceedButton={viewProceedButton}
          title={title}
        />
      ) : (
        <ConfirmationPendingContent
          loading={loading}
          href={href}
          success={success}
          subHeading={subHeading}
          onDismiss={onDismiss}
          heading={heading}
          title={title}
          isFinalStep={isFinalStep}
          onSuccessCallBack={onSuccessCallBack}
        />
      )}
    </Modal>
  )
}
