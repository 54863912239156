import { parseUnits } from '@ethersproject/units'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { LydraDetails } from 'components/lydra/LydraDetails'
import LydraHeader from 'components/lydra/LydraHeader'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import Row from 'components/Row'
import { Wrapper } from 'components/swap/styleds'
import { LYDRA } from 'constants/routing'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import {
  useHydraAccount,
  useHydraChainId,
  useHydraHexAddress,
  useHydraWalletAddress,
} from 'hooks/useAddHydraAccExtension'
import { contractSend } from 'hydra/contracts/utils'
import { useLydraContract } from 'hydra/hooks/useContract'
import { WHYDRA } from 'hydra/sdk'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useHYDRABalance } from 'state/wallets/hooks'
import styled, { css, useTheme } from 'styled-components/macro'

import AppBody from '../AppBody'

const AlertWrapper = styled.div`
  max-width: 460px;
  width: 100%;
`
const SectionWrapper = styled.div<{ redesignFlag: boolean }>`
  ${({ redesignFlag }) =>
    redesignFlag &&
    css`
      background-color: ${({ theme }) => theme.backgroundModule};
      border-radius: 12px;
      padding: 8px 12px 10px;
      color: ${({ theme }) => theme.textSecondary};
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    `}
`
const BottomInputWrapper = styled.div<{ redesignFlag: boolean }>`
  padding: ${({ redesignFlag }) => redesignFlag && '8px 0px'};
`

export default function MintBurnLydra() {
  const [typedValue, setTypedValue] = useState('')

  const theme = useTheme()
  const [account] = useHydraAccount()
  const [address] = useHydraWalletAddress()
  const [hexAddr] = useHydraHexAddress()
  const [chainId] = useHydraChainId()

  const addTransaction = useTransactionAdder()
  const { transactionData, setTransactionData } = useContext(TransactionConfirmationContext)

  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  const lydraToken = LYDRA[chainId]

  const hydraBalance = useHYDRABalance(account)?.[address]
  const lydraBalance = useTokenBalance(address, lydraToken)

  const lydraContract = useLydraContract()
  const lockedBalanceRaw: string | undefined = useSingleCallResult(lydraContract, 'lockedBalanceOf', [
    hexAddr,
  ])?.result?.[0]?.toString()
  const lockedBalance = useMemo(
    () => (lockedBalanceRaw ? CurrencyAmount.fromRawAmount(WHYDRA[chainId], lockedBalanceRaw) : null),
    [lockedBalanceRaw, chainId]
  )

  const inputCurrencyAmount = useMemo(
    () => CurrencyAmount.fromRawAmount(WHYDRA[chainId], parseUnits(typedValue || '0', 8).toString()),
    [chainId, typedValue]
  )

  const maxAmount = useMemo(() => {
    if (hydraBalance && lockedBalanceRaw) {
      const lockedCurrencyAmount = CurrencyAmount.fromRawAmount(hydraBalance.currency, lockedBalanceRaw)
      const feeReserve = CurrencyAmount.fromRawAmount(hydraBalance.currency, 25e8)

      const max = hydraBalance.subtract(lockedCurrencyAmount).subtract(feeReserve)
      return max.greaterThan(0) ? max : CurrencyAmount.fromRawAmount(hydraBalance.currency, 0)
    }

    return null
  }, [hydraBalance, lockedBalanceRaw])

  const maxInputLimit = useMemo(() => CurrencyAmount.fromRawAmount(WHYDRA[chainId], 100_000e8), [chainId])
  const atMaxAmount = Boolean(lydraBalance && inputCurrencyAmount?.equalTo(lydraBalance))

  const isMintDisabled = useMemo(() => {
    if (!maxAmount) return true

    return (
      inputCurrencyAmount.greaterThan(maxAmount) ||
      inputCurrencyAmount.equalTo(0) ||
      inputCurrencyAmount.greaterThan(maxInputLimit)
    )
  }, [maxAmount, inputCurrencyAmount, maxInputLimit])

  const isBurnDisabled = useMemo(() => {
    if (!lydraBalance || !lockedBalance) return true

    return (
      inputCurrencyAmount.greaterThan(lydraBalance) ||
      inputCurrencyAmount.equalTo(0) ||
      inputCurrencyAmount.greaterThan(lockedBalance)
    )
  }, [lydraBalance, inputCurrencyAmount, lockedBalance])

  const handleMaxInput = useCallback(() => {
    lydraBalance && onUserInput(lydraBalance.toExact())
  }, [lydraBalance, onUserInput])

  const handleMint = useCallback(() => {
    if (lydraContract && typedValue && address) {
      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Minting ${typedValue} LYDRA`,
        hash: '',
        txError: '',
      })

      contractSend(lydraContract, 'mint', [], address, 250000, Number(typedValue))
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.MINT_LYDRA,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message,
          })
          console.error(err)
        })
    }
  }, [lydraContract, typedValue, address, addTransaction, setTransactionData, transactionData])

  const handleBurn = useCallback(() => {
    if (lydraContract && typedValue && address) {
      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Burning ${typedValue} LYDRA`,
        hash: '',
        txError: '',
      })

      contractSend(lydraContract, 'burn', [`0x${inputCurrencyAmount.quotient.toString(16)}`], address, 250000)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.BURN_LYDRA,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [lydraContract, typedValue, address, addTransaction, setTransactionData, transactionData, inputCurrencyAmount])

  return (
    <>
      <AppBody>
        <LydraHeader />
        <Wrapper id="lydra-page">
          <AutoColumn gap={'0px'}>
            <SectionWrapper redesignFlag={false}>
              <AutoColumn gap={false ? '0px' : '8px'}>
                <BottomInputWrapper redesignFlag={false}>
                  <Text
                    fontSize={'14px'}
                    color={theme.deprecated_text3}
                    style={{ userSelect: 'none', cursor: 'pointer' }}
                    onClick={() => setTypedValue(maxAmount ? maxAmount.toExact() : '')}
                  >
                    <Text display={'inline-block'} fontWeight={'bold'}>
                      Max mintable:
                    </Text>{' '}
                    {maxAmount ? maxAmount.toExact() : '-'}
                  </Text>
                  <CurrencyInputPanel
                    value={typedValue}
                    onUserInput={onUserInput}
                    onMax={handleMaxInput}
                    showMaxButton={!atMaxAmount}
                    hideBalance={false}
                    currency={lydraToken}
                    id={'lydra-input'}
                  />
                </BottomInputWrapper>
              </AutoColumn>
            </SectionWrapper>
          </AutoColumn>
          <AutoColumn gap={'0px'}>
            <Row justifyContent={'center !important'} padding={'16px'}>
              <ButtonPrimary
                width={'40%'}
                padding={'10px'}
                margin={'8px'}
                disabled={isMintDisabled}
                onClick={handleMint}
              >
                Mint
              </ButtonPrimary>
              <ButtonSecondary
                width={'40%'}
                padding={'10px'}
                margin={'8px'}
                disabled={isBurnDisabled}
                onClick={handleBurn}
              >
                Burn
              </ButtonSecondary>
            </Row>
          </AutoColumn>
        </Wrapper>
        <LydraDetails lockedBalance={lockedBalance} />
      </AppBody>
      <AlertWrapper>
        <NetworkAlert />
      </AlertWrapper>
    </>
  )
}
