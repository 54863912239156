import { sendAnalyticsEvent, user } from 'components/AmplitudeAnalytics'
import { CUSTOM_USER_PROPERTIES, EventName, PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import Loader from 'components/Loader'
import TopLevelModals from 'components/TopLevelModals'
import { TransactionConfirmationProvider } from 'contexts/TransactionConfirmationContext'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { NavBarVariant, useNavBarFlag } from 'featureFlags/flags/navBar'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { getBrowser } from 'utils/browser'
import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals'

import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import { useIsExpertMode } from '../state/user/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AbiPlayer from './AbiPlayer'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import CreateIncentive from './CreateIncentive'
import DAO from './DAO'
import DelegatedStaking from './DelegatedStaking'
import Earn from './Earn'
import Manage from './Earn/Manage'
import MigrateV2 from './MigrateV2'
import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
import MintBurnLydra from './MintBurnLydra'
import Pool from './Pool'
import { PositionPage } from './Pool/PositionPage'
import PoolV2 from './Pool/v2'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import StakeToken from './StakeToken'
import StakeV3 from './StakeV3'
import StakingList from './StakingList'
import Swap from './Swap'
import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div<{ navBarFlag: NavBarVariant }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ navBarFlag }) => (navBarFlag === NavBarVariant.Enabled ? `72px 0px 0px 0px` : `120px 0px 0px 0px`)};
  align-items: center;
  flex: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 52px 0px 16px 0px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function getCurrentPageFromLocation(locationPathname: string): PageName | undefined {
  switch (locationPathname) {
    case '/swap':
      return PageName.SWAP_PAGE
    case '/pool':
      return PageName.POOL_PAGE
    case '/stake':
      return PageName.STAKING_LIST_PAGE
    case '/dao':
      return PageName.DAO_PAGE
    case '/lydra':
      return PageName.LYDRA_PAGE
    default:
      return undefined
  }
}

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded()
  const navBarFlag = useNavBarFlag()

  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const isDarkMode = useIsDarkMode()
  const isExpertMode = useIsExpertMode()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    sendAnalyticsEvent(EventName.APP_LOADED)
    user.set(CUSTOM_USER_PROPERTIES.BROWSER, getBrowser())
    user.set(CUSTOM_USER_PROPERTIES.SCREEN_RESOLUTION_HEIGHT, window.screen.height)
    user.set(CUSTOM_USER_PROPERTIES.SCREEN_RESOLUTION_WIDTH, window.screen.width)
    getCLS(({ delta, id }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { cumulative_layout_shift: delta }))
    getFCP(({ delta, id }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { first_contentful_paint_ms: delta }))
    getFID(({ delta, id }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { first_input_delay_ms: delta }))
    getLCP(({ delta, id }: Metric) => sendAnalyticsEvent(EventName.WEB_VITALS, { largest_contentful_paint_ms: delta }))
  }, [])

  useEffect(() => {
    user.set(CUSTOM_USER_PROPERTIES.DARK_MODE, isDarkMode)
  }, [isDarkMode])

  useEffect(() => {
    user.set(CUSTOM_USER_PROPERTIES.EXPERT_MODE, isExpertMode)
  }, [isExpertMode])

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <ApeModeQueryParamReader />
      <AppWrapper>
        <TransactionConfirmationProvider>
          <Trace page={currentPage}>
            <HeaderWrapper>{<Header />}</HeaderWrapper>
            <BodyWrapper navBarFlag={navBarFlag}>
              <Popups />
              <Polling />
              <TopLevelModals />
              <Suspense fallback={<Loader />}>
                {isLoaded ? (
                  <Routes>
                    <Route path="stake" element={<StakingList />} />
                    <Route path="stake/hydra" element={<DelegatedStaking />} />
                    <Route path="stake/:tokenIdA" element={<StakeToken />} />
                    <Route path="stake/incentive/:incentiveId" element={<StakeV3 />} />

                    <Route path="dao" element={<DAO />} />

                    <Route path="lydra" element={<MintBurnLydra />} />

                    <Route path="incentive/create" element={<CreateIncentive />} />
                    <Route path="abi-player" element={<AbiPlayer />} />

                    <Route path="claim" element={<OpenClaimAddressModalAndRedirectToSwap />} />
                    <Route path="uni" element={<Earn />} />
                    <Route path="uni/:currencyIdA/:currencyIdB" element={<Manage />} />

                    <Route path="send" element={<RedirectPathToSwapOnly />} />
                    <Route path="swap/:outputCurrency" element={<RedirectToSwap />} />
                    <Route path="swap" element={<Swap />} />

                    <Route path="pool/v2/find" element={<PoolFinder />} />
                    <Route path="pool/v2" element={<PoolV2 />} />
                    <Route path="pool" element={<Pool />} />
                    <Route path="pool/:tokenId" element={<PositionPage />} />

                    <Route path="add/v2" element={<RedirectDuplicateTokenIdsV2 />}>
                      <Route path=":currencyIdA" />
                      <Route path=":currencyIdA/:currencyIdB" />
                    </Route>
                    <Route path="add" element={<RedirectDuplicateTokenIds />}>
                      {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more */}
                      <Route path=":currencyIdA" />
                      <Route path=":currencyIdA/:currencyIdB" />
                      <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                    </Route>

                    <Route path="increase" element={<AddLiquidity />}>
                      <Route path=":currencyIdA" />
                      <Route path=":currencyIdA/:currencyIdB" />
                      <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                      <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                    </Route>

                    <Route path="remove/v2/:currencyIdA/:currencyIdB" element={<RemoveLiquidity />} />
                    <Route path="remove/:tokenId" element={<RemoveLiquidityV3 />} />

                    <Route path="migrate/v2" element={<MigrateV2 />} />
                    <Route path="migrate/v2/:address" element={<MigrateV2Pair />} />

                    <Route path="*" element={<RedirectPathToSwapOnly />} />
                  </Routes>
                ) : (
                  <Loader />
                )}
              </Suspense>
              <Marginer />
            </BodyWrapper>
          </Trace>
        </TransactionConfirmationProvider>
      </AppWrapper>
    </ErrorBoundary>
  )
}
