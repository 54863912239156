import { Interface } from '@ethersproject/abi'
import { id } from '@ethersproject/hash'
import { ButtonOutlined, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import { Input as NumericalInput } from 'components/NumericalInput'
import { TextInput } from 'components/TextInput'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { useHydraLibrary, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { rawCall, rawSend } from 'hydra/contracts/rawFunctions'
import { Box } from 'nft/components/Box'
import { Column } from 'nft/components/Flex'
import { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'

import { AbiMethod, AbiMethodsTab } from '.'

const DropdownOption = ({ onClick, method }: { onClick: () => void; method: AbiMethod }) => {
  return (
    <ButtonOutlined padding={'4px 16px'} onClick={onClick}>
      {method.name}
    </ButtonOutlined>
  )
}

interface DropdownParams {
  options: AbiMethod[]
  type: AbiMethodsTab
  abi: any[]
  address: string
}

export const Dropdown = ({ options, type, abi, address }: DropdownParams) => {
  const [library] = useHydraLibrary()
  const [account] = useHydraWalletAddress()
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)
  const [selectedMethod, setSelectedMethod] = useState<AbiMethod | null>(null)
  const [params, setParams] = useState<any[]>([])
  const [results, setResults] = useState<any[]>([])
  const [amountHydra, setAmountHydra] = useState('0')
  const [gasLimit, setGasLimit] = useState('250000')

  useEffect(() => {
    setParams([])
    setResults([])
    setSelectedMethod(options[0])
    setAmountHydra('0')
    setGasLimit('250000')
  }, [options])

  useEffect(() => {
    setResults([])
    setParams([])
    setAmountHydra('0')
    setGasLimit('250000')
  }, [selectedMethod])

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const { transactionData, setTransactionData } = useContext(TransactionConfirmationContext)

  const handleInput = useCallback(
    (value: string, index: number) => {
      const newParams = [...params]
      newParams[index] = value
      setParams(newParams)
    },
    [params]
  )

  const handleCall = useCallback(() => {
    try {
      if (!account || !library || !selectedMethod || !address || !abi.length) {
        return
      }

      const iface = new Interface(abi)
      const func = `${selectedMethod.name}(${selectedMethod.inputs.map((x) => x.type)})`
      const signature = id(func).substring(0, 10)

      const hex = iface.encodeFunctionData(signature, params).substring(2)

      rawCall(library, address, hex, account)
        .then(({ executionResult }) => {
          if (executionResult.excepted === 'None') {
            const res = iface.decodeFunctionResult(signature, '0x' + executionResult.output)
            setResults([...res])
          } else {
            throw new Error(executionResult.exceptedMessage)
          }
        })
        .catch((e) => {
          console.log(e)
          alert(e.message)
        })
    } catch (error) {
      console.log(error)
    }
  }, [abi, selectedMethod, address, library, account, params])

  const handleSend = useCallback(() => {
    try {
      if (!account || !library || !selectedMethod || !address || !abi.length) {
        return
      }

      const iface = new Interface(abi)
      // const func = `${selectedMethod.name}(${selectedMethod.inputs.map((x) => x.type)})`
      // const signature = id(func).substring(0, 10)

      const mappedParams = params.map((x, i) => {
        try {
          if (selectedMethod.inputs[i].type === 'tuple') {
            return JSON.parse(x.replaceAll(`'`, `"`))
          }
          return x
        } catch (error) {
          console.log(error)
          return x
        }
      })

      const hex = iface.encodeFunctionData(selectedMethod.name, mappedParams).substring(2)

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Sending transaction...`,
        hash: '',
        txError: '',
      })
      rawSend(library, address, hex, amountHydra ?? '0', account, Number(gasLimit) || 250000)
        .then((tx) => {
          tx.hash = tx.id
          addTransaction(tx, {
            type: TransactionType.SEND_TRANSACTION,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((e) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: e?.message ?? '',
          })
          console.error(e)
        })
    } catch (error) {
      console.log(error)
    }
  }, [
    abi,
    selectedMethod,
    address,
    library,
    account,
    params,
    addTransaction,
    setTransactionData,
    transactionData,
    gasLimit,
    amountHydra,
  ])

  return (
    <>
      <Box position="relative" ref={ref} marginTop={'16'}>
        <ButtonOutlined padding={'4px 24px'} onClick={toggleOpen}>
          Methods {'>'}
        </ButtonOutlined>

        {isOpen && (
          <NavDropdown top={60}>
            <Column gap="12">
              {options.map((method, i) => (
                <Column paddingX="16" gap="4" key={i}>
                  <DropdownOption
                    onClick={() => {
                      toggleOpen()
                      setSelectedMethod(method)
                    }}
                    method={method}
                  />
                </Column>
              ))}
            </Column>
          </NavDropdown>
        )}
      </Box>
      {selectedMethod && (
        <>
          <Text marginTop={'16px'} fontSize={'16px'} fontWeight={'600'}>
            {selectedMethod.name}
          </Text>
          <AutoColumn gap="sm">
            {selectedMethod.inputs.map((x, i) => (
              <label key={i}>
                {x.name} {`(${x.type})`}
                <TextInput
                  onUserInput={(value) => handleInput(value, i)}
                  value={params[i] ?? ''}
                  placeholder={x.name}
                  fontSize={'16px'}
                />
              </label>
            ))}
          </AutoColumn>

          {account && (
            <AutoColumn style={{ marginTop: '16px' }} gap="sm">
              {type === AbiMethodsTab.READ ? (
                <>
                  <ButtonPrimary width={'250px'} padding={'8px 12px'} onClick={handleCall}>
                    Call
                  </ButtonPrimary>
                </>
              ) : (
                <>
                  <ButtonPrimary width={'250px'} padding={'8px 12px'} onClick={handleSend}>
                    Send
                  </ButtonPrimary>
                  <label>
                    Amount Hydra
                    <NumericalInput
                      style={{ display: 'block', width: '100%' }}
                      onUserInput={(value) => setAmountHydra(value)}
                      value={amountHydra}
                      placeholder={'Amount Hydra'}
                      fontSize={'16px'}
                    />
                  </label>

                  <label>
                    Gas Limit
                    <NumericalInput
                      style={{ display: 'block', width: '100%' }}
                      onUserInput={(value) => setGasLimit(value)}
                      value={gasLimit}
                      placeholder={'Gas Limit'}
                      fontSize={'16px'}
                    />
                  </label>
                </>
              )}
            </AutoColumn>
          )}

          {results.length > 0 && (
            <AutoColumn style={{ marginTop: '16px' }} gap="sm">
              {results.map((x, i) => (
                <Text key={i}>
                  {selectedMethod.outputs[i]?.name} {`(${selectedMethod.outputs[i]?.type}):`} {x?.toString()}
                </Text>
              ))}
            </AutoColumn>
          )}
        </>
      )}
    </>
  )
}
