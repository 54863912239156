import { createMulticall, ListenerOptions } from '@uniswap/redux-multicall'
import { SupportedChainId } from 'constants/chains'
import { useHydraChainId, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { contractCall } from 'hydra/contracts/utils'
import { useMulticallContract } from 'hydra/hooks/useContract'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useMemo } from 'react'
import { combineReducers, createStore } from 'redux'

const multicall = createMulticall()
const reducer = combineReducers({ [multicall.reducerPath]: multicall.reducer })
export const store = createStore(reducer)
export default multicall

function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
    case SupportedChainId.OPTIMISM:
      return 15
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
      return 5
    default:
      return 1
  }
}

export function MulticallUpdater() {
  const [chainId] = useHydraChainId()
  const [account] = useHydraWalletAddress()
  const latestBlockNumber = useBlockNumber()
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  )
  const multicallContract = useMulticallContract()
  const contractMock = {
    callStatic: {
      multicall: async (chunk: { target: string; callData: string }[]) => {
        const res = { success: false, gasUsed: 0, returnData: [{ success: false, returnData: '0x' }] }

        if (!multicallContract) {
          res.returnData = chunk.map(() => ({ success: false, returnData: '0x' }))
        } else {
          // split chunks in max 100 calls to avoid error 413
          let returnData: typeof res.returnData = []
          const chunkCounter = Math.ceil(chunk.length / 100)
          for (let i = 0; i < chunkCounter; i++) {
            const shortChunk = chunk.slice(i * 100, (i + 1) * 100) // chunk of 100 calls
            const { executionResult } = await contractCall(multicallContract, 'multicall', [shortChunk], account)

            if (executionResult?.excepted === 'None') {
              returnData = [
                ...returnData,
                ...shortChunk.map((_, i) => ({
                  success: executionResult.formattedOutput[1][i][0],
                  returnData: executionResult.formattedOutput[1][i][2],
                })),
              ]
            } else {
              returnData = [
                ...returnData,
                ...shortChunk.map((_, i) => ({
                  success: false,
                  returnData: executionResult.formattedOutput[1][i][2],
                })),
              ]
            }
          }

          res.returnData = returnData
        }

        return res
      },
    },
  }

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contractMock}
      listenerOptions={listenerOptions}
    />
  )
}
