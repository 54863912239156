import { BigNumber } from '@ethersproject/bignumber'
import JSBI from 'jsbi'

export function sqrtToPrice(sqrtPriceX96: JSBI, token0Decimals: number, token1Decimals: number, precision: number) {
  const sqrtPrice = BigNumber.from(sqrtPriceX96.toString())
  return (
    sqrtPrice
      .mul(sqrtPrice)
      .mul(BigNumber.from(10).pow(token0Decimals + precision))
      .div(BigNumber.from(10).pow(token1Decimals))
      .div(BigNumber.from(2).pow(192))
      .toNumber() /
    10 ** precision
  )
}

export function priceToSqrt(price: number, token0Decimals: number, token1Decimals: number, precision: number) {
  const floatPrice = BigNumber.from(Math.floor(price * 10 ** precision))
  return sqrt(
    floatPrice
      .mul(BigNumber.from(2).pow(192))
      .mul(BigNumber.from(10).pow(token1Decimals))
      .div(BigNumber.from(10).pow(token0Decimals + precision))
  )
}

function sqrt(value: BigNumber) {
  const ONE = BigNumber.from(1)
  const TWO = BigNumber.from(2)

  const x = BigNumber.from(value)
  let z = x.add(ONE).div(TWO)
  let y = x
  while (z.sub(y).isNegative()) {
    y = z
    z = x.div(z).add(z).div(TWO)
  }
  return y
}
