import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { HeaderText } from 'pages/StakingList/styleds'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { Text } from 'rebass'

import PriceInput from './PriceInput'

export default function PositionCalculatorWidget({
  calculatorValues,
  setCalculatorValues,
}: {
  calculatorValues: { lower: string; upper: string }
  setCalculatorValues: Dispatch<SetStateAction<typeof calculatorValues>>
}) {
  const handleLowerInput = useCallback(
    (input: string) => {
      setCalculatorValues((values) => ({ ...values, lower: input }))
    },
    [setCalculatorValues]
  )

  const handleHigherInput = useCallback(
    (input: string) => {
      setCalculatorValues((values) => ({ ...values, upper: input }))
    },
    [setCalculatorValues]
  )

  return (
    <AutoColumn gap="md">
      <HeaderText>
        <Text>V3 Position Staking APR Calculator</Text>
      </HeaderText>
      <RowBetween>
        <PriceInput
          value={calculatorValues.lower}
          onUserInput={handleLowerInput}
          width="48%"
          title={<Text>Lowest</Text>}
          prependSymbol={'-'}
        />
        <PriceInput
          value={calculatorValues.upper}
          onUserInput={handleHigherInput}
          width="48%"
          title={<Text>Highest</Text>}
        />
      </RowBetween>
    </AutoColumn>
  )
}
