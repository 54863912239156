import styled from 'styled-components/macro'

export const StakedAmountButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.deprecated_text2};
`
