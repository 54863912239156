import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { Currency } from 'hydra/sdk'
import { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'

import { TOOLTIP_MESSAGE_FOR_SPLIT_UTXO } from './constants'

const DetailViewCardWrapper = styled.div`
  width: 90%;
  border-radius: 20px;
  color: ${({ theme }) => theme.deprecated_text1};
  background-color: ${({ theme }) => theme.deprecated_bg3};
  z-index: -1;
  @media (max-width: 720px) {
    width: 100%;
    max-width: 400px;
  }
`
const AdvancedDetails = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  display: none;
  @media (max-width: 720px) {
    display: grid;
  }
`

const MatricsCard = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 0px) and (max-width: 720px) {
    display: none;
  }
`
const MatricsCardContent = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  @media (min-width: 0px) and (max-width: 720px) {
    display: none;
  }
`
const ButtonWrapper = styled.div`
  @media (min-width: 0px) and (max-width: 720px) {
    width: 50%;
    margin: 0 auto;
  }
`
const HeaderWrapper = styled.div`
  width: 90%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  @media (min-width: 0px) and (max-width: 720px) {
    display: block;
  }
`

interface Props {
  accountHydra?: string
  apy: string | null
  apyPercentage: string | null
  currency: Currency | undefined
  balance: any
  onClickSplitUTXO?: () => void
}

export default function DetailViewCard({
  apy,
  apyPercentage,
  currency,
  balance,
  accountHydra,
  onClickSplitUTXO,
}: Props) {
  const theme = useContext(ThemeContext)

  return (
    <>
      <HeaderWrapper>
        <Text fontSize={'20px'} textAlign={'center'} padding={10} style={{ justifySelf: 'center' }}>
          Hydra Superstaker Delegation
        </Text>
        <ButtonWrapper>
          <ButtonPrimary
            disabled={!accountHydra}
            minWidth="150px"
            padding="12px"
            // borderRadius="20px!important"
            onClick={onClickSplitUTXO && onClickSplitUTXO}
          >
            <MouseoverTooltip text={TOOLTIP_MESSAGE_FOR_SPLIT_UTXO}>Split UTXO</MouseoverTooltip>
          </ButtonPrimary>
        </ButtonWrapper>
      </HeaderWrapper>
      <DetailViewCardWrapper>
        <MatricsCard>
          <MatricsCardContent style={{ justifyContent: 'flex-start' }}>
            <Text fontSize={14} fontWeight={'bold'} color={theme.deprecated_text1}>
              Balance: &nbsp;&nbsp;
            </Text>
            <Text color={theme.deprecated_text1} fontSize={14} opacity={0.8}>
              {`${balance || `0.00`} ${currency?.symbol}`}
            </Text>
          </MatricsCardContent>
          <MatricsCardContent style={{ justifyContent: 'center' }}>
            <Text fontSize={14} fontWeight={'bold'} color={theme.deprecated_text1}>
              APY: &nbsp;&nbsp;
            </Text>
            <Text color={theme.deprecated_text1} fontSize={14} opacity={0.8}>
              {apyPercentage}%
            </Text>
          </MatricsCardContent>
          <MatricsCardContent style={{ justifyContent: 'flex-end ' }}>
            <Text fontSize={14} fontWeight={'bold'} color={theme.deprecated_text1}>
              Projected Annual Reward: &nbsp;&nbsp;
            </Text>
            <Text color={theme.deprecated_text1} fontSize={14} opacity={0.8}>
              {apy || `0.00`} {currency?.symbol}
            </Text>
          </MatricsCardContent>
        </MatricsCard>
        <AdvancedDetails>
          <AutoColumn gap="md">
            {balance && (
              <RowBetween padding="0 16px">
                <Text fontSize={14} fontWeight={'bold'} color={theme.deprecated_text1}>
                  Balance
                </Text>
                <Text color={theme.deprecated_text1} fontSize={14} opacity={0.8}>
                  {`${balance} ${currency?.symbol}`}
                </Text>
              </RowBetween>
            )}
            {apy && (
              <RowBetween padding="0 16px">
                <Text fontSize={14} fontWeight={'bold'} color={theme.deprecated_text1}>
                  Projected Annual Reward
                </Text>
                <Text color={theme.deprecated_text1} fontSize={14} opacity={0.8}>
                  {apy} {currency?.symbol}
                </Text>
              </RowBetween>
            )}
            {apyPercentage && (
              <RowBetween padding="0 16px">
                <Text fontSize={14} fontWeight={'bold'} color={theme.deprecated_text1}>
                  APY
                </Text>
                <Text color={theme.deprecated_text1} fontSize={14} opacity={0.8}>
                  {apyPercentage}%
                </Text>
              </RowBetween>
            )}
          </AutoColumn>
        </AdvancedDetails>
      </DetailViewCardWrapper>
    </>
  )
}
