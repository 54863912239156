import { darken } from 'polished'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`

export const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const activeClassName = 'active'
export const StyledTabOption = styled.button`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 0 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  background: none;
  outline: none;
  border: 1px solid;
  transition: 0.15s ease-out;
  &.${activeClassName} {
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
    background-color: ${({ theme }) => theme.deprecated_bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`
