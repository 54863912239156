import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import Card, { OutlineCard } from 'components/Card'
import styled, { useTheme } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'

const StyledCard = styled(Card)`
  padding: 0;
`
const WrapperCard = styled(OutlineCard)`
  padding: 12px;
  max-width: 390px;
  position: absolute;
  left: 50%;
  z-index: -1;
  top: 100%;
  border-top: 0;
  border-radius: 0 0 16px 16px;
  backdrop-filter: blur(5px);
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  transform: translate(-50%, 0%);

  @media (max-width: 720px) {
    margin-top: 0;
  }
`
export function LydraDetails({ lockedBalance }: { lockedBalance: CurrencyAmount<Currency> | null }) {
  const theme = useTheme()

  return (
    <WrapperCard backgroundColor={theme.deprecated_advancedBG}>
      <StyledCard>
        <AutoColumn gap="8px">
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text2}>
                <Trans>Total Locked:</Trans>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            <ThemedText.DeprecatedMain textAlign="right" fontSize={14}>
              {lockedBalance?.toExact() ?? '-'} HYDRA
            </ThemedText.DeprecatedMain>
          </RowBetween>
        </AutoColumn>
      </StyledCard>
    </WrapperCard>
  )
}
