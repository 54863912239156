// Contract addresses
const WHYDRA_ADDRESS = '6d9115a21863ce31b44cd231e4c4ccc87566222f'

const STAKING__CHANGEX_ADDRESS = '72c9a79baa83e698bf1dbf44d26e5bdca2d2bab1'
const CHANGEX_ADDRESS = 'bd3c617d271b3467bd9b83dda73c9288de2fb0c9'

const STAKING__DAIHYDRA_ADDRESS = 'e11670579701f07f67796dbf8046a941923f693e'
const DAIHYDRA_ADDRESS = '7c9cba56ebc25265067bbe2c111f9acd47fc2f14'

const STAKING__ETHHYDRA_ADDRESS = '015d7fd65db068f3e545132b1d4155bb946473af'
const ETHHYDRA_ADDRESS = '9260761f5c1a29938087bc689454041fd0132863'

const STAKING__WBTCHYDRA_ADDRESS = '73437c7f4c9b379aa28f82d1ed1263d38509d489'
const WBTCHYDRA_ADDRESS = '0a2ae30701095ee10ae461f0504f14004e27f74a'

const STAKING__DAIUSDC_ADDRESS = '7323264b9922473245f37478053fceab88a8a882'
const DAIUSDC_ADDRESS = '686ddb0abda225be16d04cff311a367493bd639b'

const STAKING__BUSDUSDC_ADDRESS = 'd692cee64b3e9c7f09bf19c778865c39c6481be7'
const BUSDUSDC_ADDRESS = 'd92bf3ac6a2a7be2bf98fe534f0b8dcfe28d7833'

const STAKING__USDTUSDC_ADDRESS = '8dca767938e451e82c17a590150516a46978884a'
const USDTUSDC_ADDRESS = '9abeef1738cd95f5f92c1cfd5ec574dfacfc4ea1'

const STAKING__USDCHYDRA_ADDRESS = '071214f2d83fc1e21c21068e6e0b084732c0aff7'
const USDCHYDRA_ADDRESS = '2ac2b678dd71ffd27431524741d4957039620489'

export const STAKING_TOKENS = {
  [CHANGEX_ADDRESS]: {
    name: 'ChangeX',
    stakingContract: {
      address: STAKING__CHANGEX_ADDRESS,
    },
    stakingToken: {
      isPair: false,
      address: CHANGEX_ADDRESS,
      decimals: 18,
    },
    rewardToken: {
      address: CHANGEX_ADDRESS,
      decimals: 18,
    },
    endTime: '1681567760',
  },
}

export const STAKING_LP_TOKENS = {
  [DAIHYDRA_ADDRESS]: {
    name: 'DAI:HYDRA (Discontinued)',
    stakingContract: {
      address: STAKING__DAIHYDRA_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: DAIHYDRA_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1672483456',
  },
  [ETHHYDRA_ADDRESS]: {
    name: 'ETH:HYDRA Liquidity Pool',
    stakingContract: {
      address: STAKING__ETHHYDRA_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: ETHHYDRA_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1680367552',
  },
  [WBTCHYDRA_ADDRESS]: {
    name: 'WBTC:HYDRA Liquidity Pool',
    stakingContract: {
      address: STAKING__WBTCHYDRA_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: WBTCHYDRA_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1680367648',
  },
  [DAIUSDC_ADDRESS]: {
    name: 'DAI:USDC Liquidity Pool',
    stakingContract: {
      address: STAKING__DAIUSDC_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: DAIUSDC_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1680367372',
  },
  [BUSDUSDC_ADDRESS]: {
    name: 'BUSD:USDC Liquidity Pool',
    stakingContract: {
      address: STAKING__BUSDUSDC_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: BUSDUSDC_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1680367280',
  },
  [USDTUSDC_ADDRESS]: {
    name: 'USDT:USDC Liquidity Pool',
    stakingContract: {
      address: STAKING__USDTUSDC_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: USDTUSDC_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1680367424',
  },
  [USDCHYDRA_ADDRESS]: {
    name: 'USDC:HYDRA Liquidity Pool',
    stakingContract: {
      address: STAKING__USDCHYDRA_ADDRESS,
    },
    stakingToken: {
      isPair: true,
      address: USDCHYDRA_ADDRESS,
      decimals: 8,
    },
    rewardToken: {
      address: WHYDRA_ADDRESS,
      decimals: 8,
    },
    endTime: '1680367464',
  },
}
