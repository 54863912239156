import { Trans } from '@lingui/macro'
import { OutlineCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { Input as NumericalInput } from '../NumericalInput'

const FocusedOutlineCard = styled(OutlineCard)<{ active?: boolean }>`
  border-color: ${({ active, theme }) => active && theme.deprecated_blue1};
  padding: 12px;
`

const StyledInput = styled(NumericalInput)<{ usePercent?: boolean }>`
  background-color: transparent;
  text-align: center;
  width: 100%;
  font-weight: 500;
  padding: 0 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px;
  `};
`

const InputTitle = styled(ThemedText.DeprecatedSmall)`
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 12px;
  font-weight: 500;
`

interface PriceInputProps {
  value: string
  onUserInput: (value: string) => void
  width?: string
  title: ReactNode
  prependSymbol?: string
}

const PriceInput = ({ value, width, onUserInput, title, prependSymbol }: PriceInputProps) => {
  //  for focus state, styled components doesnt let you select input parent container
  const [active, setActive] = useState(false)

  // let user type value and only update parent value on blur
  const [localValue, setLocalValue] = useState('')
  const [useLocalValue, setUseLocalValue] = useState(false)

  const handleOnFocus = () => {
    setUseLocalValue(true)
    setActive(true)
  }

  const handleOnBlur = useCallback(() => {
    setUseLocalValue(false)
    setActive(false)
    onUserInput(localValue) // trigger update on parent value
  }, [localValue, onUserInput])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (localValue !== value && !useLocalValue) {
      timeout = setTimeout(() => {
        setLocalValue(value) // reset local value to match parent
      }, 0)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [localValue, useLocalValue, value])

  return (
    <FocusedOutlineCard active={active} onFocus={handleOnFocus} onBlur={handleOnBlur} width={width}>
      <AutoColumn gap="6px">
        <InputTitle fontSize={12} textAlign="center">
          {title}
        </InputTitle>

        <StyledInput
          className="price-input-0"
          value={localValue}
          fontSize="20px"
          onUserInput={(val) => {
            setLocalValue(val)
          }}
          prependSymbol={prependSymbol}
        />

        <InputTitle fontSize={12} textAlign="center">
          <Trans>Price change in %</Trans>
        </InputTitle>
      </AutoColumn>
    </FocusedOutlineCard>
  )
}

export default PriceInput
