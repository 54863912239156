// import { isSupportedChainId } from 'lib/hooks/routing/clientSideSmartOrderRouter'

// import { useHydraChainId } from './useAddHydraAccExtension'

export default function useAutoRouterSupported(): boolean {
  // const [chainId] = useHydraChainId()
  // return isSupportedChainId(chainId)

  return false
}
