interface stakingConfig {
  name: string
  tokenIdA: string
  tokenIdB: string
  fee: number
  key: {
    rewardToken: string
    pool: string
    startTime: string
    endTime: string
    refundee: string
  }
  reward: string
}

// ["2f859738695d1699844ec197359f55c306c4eb74","0x4C968962b531c0205DE0f59A5b4C9D8063e77E82","1674853200","1674939600","c87d0a7f4539b2088600d8cb4fe92abe2e1bdb92"]

// Production/Mainnet LM Campaigns
export const STAKING_V3_POOLS: stakingConfig[] = [
  {
    name: 'USDT:USDC',
    tokenIdA: '932917c89717c809f67ebb421e24fd04d7a96d33',
    tokenIdB: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    fee: 500,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: '76d7e52c6baa06a55c12f94e5c7d5b478d374ba4',
      startTime: '1726318101',
      endTime: '1733140122',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },

    reward: '5200',
  },
  {
    name: 'DAI:USDC',
    tokenIdA: 'abc2cd00700e06922bcf30fe0ad648507113cc56',
    tokenIdB: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    fee: 500,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: '0fa4f742e3a951789160ee5e0ce40a0e8552e68f',
      startTime: '1726318101',
      endTime: '1733140122',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '5200',
  },
  {
    name: 'WBTC:ETH',
    tokenIdA: '27a459ac47204f1e7fc5c48ffeafa990af244e13',
    tokenIdB: 'ab9e970de9ba9ec2c64a7f7f911da44b750ecf6a',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'c87304d690c61d58a5593859c7f3fcc4eea1d1ce',
      startTime: '1726318101',
      endTime: '1733140122',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '7800',
  },
  {
    name: 'USDC:LYDRA',
    tokenIdA: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    tokenIdB: '68a674e10694eeb00867d2e11c8b158b00b28e96',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'f9ce49888be09cec56bf1682adc272efc070ff34',
      startTime: '1726318101',
      endTime: '1733140122',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '13000',
  },
  {
    name: 'ETH:LYDRA',
    tokenIdA: 'ab9e970de9ba9ec2c64a7f7f911da44b750ecf6a',
    tokenIdB: '68a674e10694eeb00867d2e11c8b158b00b28e96',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'b0330dc8d7323d706b98eb2c71f382e9ed48c98b',
      startTime: '1726318101',
      endTime: '1733140122',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '13000',
  },
  {
    name: 'WBTC:LYDRA',
    tokenIdA: '27a459ac47204f1e7fc5c48ffeafa990af244e13',
    tokenIdB: '68a674e10694eeb00867d2e11c8b158b00b28e96',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'b7399a88a46168e48e9b26218f34a53a1e83ee88',
      startTime: '1726318101',
      endTime: '1733140122',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '13000',
  },
  // EXPIRED DOWN
  {
    name: 'USDT:USDC',
    tokenIdA: '932917c89717c809f67ebb421e24fd04d7a96d33',
    tokenIdB: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    fee: 500,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: '76d7e52c6baa06a55c12f94e5c7d5b478d374ba4',
      startTime: '1715855902',
      endTime: '1726220326',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },

    reward: '8000',
  },
  {
    name: 'DAI:USDC',
    tokenIdA: 'abc2cd00700e06922bcf30fe0ad648507113cc56',
    tokenIdB: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    fee: 500,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: '0fa4f742e3a951789160ee5e0ce40a0e8552e68f',
      startTime: '1715855902',
      endTime: '1726220326',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '8000',
  },
  {
    name: 'WBTC:ETH',
    tokenIdA: '27a459ac47204f1e7fc5c48ffeafa990af244e13',
    tokenIdB: 'ab9e970de9ba9ec2c64a7f7f911da44b750ecf6a',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'c87304d690c61d58a5593859c7f3fcc4eea1d1ce',
      startTime: '1715855902',
      endTime: '1726220326',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '12000',
  },
  {
    name: 'USDC:LYDRA',
    tokenIdA: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    tokenIdB: '68a674e10694eeb00867d2e11c8b158b00b28e96',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'f9ce49888be09cec56bf1682adc272efc070ff34',
      startTime: '1715855902',
      endTime: '1726220326',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '20000',
  },
  {
    name: 'ETH:LYDRA',
    tokenIdA: 'ab9e970de9ba9ec2c64a7f7f911da44b750ecf6a',
    tokenIdB: '68a674e10694eeb00867d2e11c8b158b00b28e96',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'b0330dc8d7323d706b98eb2c71f382e9ed48c98b',
      startTime: '1715855902',
      endTime: '1726220326',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '20000',
  },
  {
    name: 'WBTC:LYDRA',
    tokenIdA: '27a459ac47204f1e7fc5c48ffeafa990af244e13',
    tokenIdB: '68a674e10694eeb00867d2e11c8b158b00b28e96',
    fee: 3000,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: 'b7399a88a46168e48e9b26218f34a53a1e83ee88',
      startTime: '1715855902',
      endTime: '1726220326',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '20000',
  },
  {
    name: 'BUSD:USDC (Expired)',
    tokenIdA: '0c404645b9e36b6cf9bcaf2747e472334b268e9d',
    tokenIdB: 'e4b8164dccf3489f66124cace1570dc35b58fc90',
    fee: 500,
    key: {
      rewardToken: '6d9115a21863ce31b44cd231e4c4ccc87566222f',
      pool: '9c1d619de2d99fb187f530c2286b1773680a873e',
      startTime: '1698855636',
      endTime: '1702748062',
      refundee: 'd7e82a0d4bc7302b50b8c610624600b18da7a246',
    },
    reward: '3000',
  },
]
// TESTNET INCENTIVES
export const TESTNET_STAKING_V3_POOLS: stakingConfig[] = [
  {
    name: '[Testnet] DAI/HYDRA 0.3% V3 Pool',
    tokenIdA: '2f859738695d1699844ec197359f55c306c4eb74',
    tokenIdB: 'cc74ab8d0e2366956b3cb6d8a8c7f14872c9a987',
    fee: 3000,
    key: {
      rewardToken: 'cc74ab8d0e2366956b3cb6d8a8c7f14872c9a987',
      pool: '4c968962b531c0205de0f59a5b4c9d8063e77e82',
      startTime: '1688025601',
      endTime: '1719583200',
      refundee: 'c79b09346bdd6b015e2c103fc4cdea4e4ff9c029',
    },
    reward: '150',
  },
]

// ['cc74ab8d0e2366956b3cb6d8a8c7f14872c9a987', '4c968962b531c0205de0f59a5b4c9d8063e77e82', '1680528631', '1681133431', 'c79b09346bdd6b015e2c103fc4cdea4e4ff9c029']
