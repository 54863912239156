import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

const Section = styled(AutoColumn)`
  padding: 24px;
`

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export default function ConfirmationModal({
  title,
  onDismiss,
  onConfirm,
  modalContentText,
}: {
  title: string
  onDismiss: () => void
  onConfirm: () => void
  modalContentText: string
}) {
  return (
    <>
      <ConfirmationModalContent
        title={title}
        bottomContent={() => (
          <BottomSection>
            <ButtonSecondary
              padding="18px"
              style={{ borderRadius: '20px!important' }}
              onClick={onDismiss}
              width={'50%'}
            >
              No
            </ButtonSecondary>
            <ButtonPrimary padding="18px" style={{ borderRadius: '20px!important' }} onClick={onConfirm} width={'50%'}>
              Yes
            </ButtonPrimary>
          </BottomSection>
        )}
        onDismiss={onDismiss}
        topContent={() => (
          <>
            <Section>
              <AutoColumn gap="12px" justify={'center'}>
                <AutoColumn gap="12px" justify={'center'}>
                  <Text fontWeight={600} fontSize={14} color="" textAlign="center">
                    {modalContentText}
                  </Text>
                </AutoColumn>
              </AutoColumn>
            </Section>
          </>
        )}
      />
    </>
  )
}
