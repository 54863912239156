import { Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade } from 'hydra-router-sdk'
import useSwapApproval from 'lib/hooks/swap/useSwapApproval'
import { ApprovalState, useApproval, usePositionApproval } from 'lib/hooks/useApproval'
import { useCallback } from 'react'

import { useHasPendingApproval, usePositionHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
export { ApprovalState } from 'lib/hooks/useApproval'

function useGetAndTrackApproval(getApproval: ReturnType<typeof useApproval>[1]) {
  const addTransaction = useTransactionAdder()
  return useCallback(() => {
    return getApproval().then((pending) => {
      if (pending) {
        const { response, tokenAddress, spenderAddress: spender } = pending
        addTransaction(response, { type: TransactionType.APPROVAL, tokenAddress, spender })
      }
    })
  }, [addTransaction, getApproval])
}

function useGetAndTrackPositionApproval(getApproval: ReturnType<typeof usePositionApproval>[1]) {
  const addTransaction = useTransactionAdder()
  return useCallback(() => {
    return getApproval().then((pending) => {
      if (pending) {
        const { response, tokenId, operator } = pending
        addTransaction(response, { type: TransactionType.POSITION_APPROVAL, tokenId, operator })
      }
    })
  }, [addTransaction, getApproval])
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useApproval(amountToApprove, spender, useHasPendingApproval)
  return [approval, useGetAndTrackApproval(getApproval)]
}

export function useApproveCallbackFromTrade(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: Percent
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useSwapApproval(trade, allowedSlippage, useHasPendingApproval)
  return [approval, useGetAndTrackApproval(getApproval)]
}

export function usePositionApproveCallback(tokenId?: string, operator?: string): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = usePositionApproval(tokenId, operator, usePositionHasPendingApproval)
  return [approval, useGetAndTrackPositionApproval(getApproval)]
}
