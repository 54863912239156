import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Row'
import Toggle from 'components/Toggle'
import { Text } from 'rebass'
import { useOptimizedTrade } from 'state/user/hooks'
import { useTheme } from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'

export default function OptimizedMode() {
  const theme = useTheme()
  const [optimizedTrade, setOptimizedTrade] = useOptimizedTrade()

  return (
    <RowBetween>
      <RowFixed>
        <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} color={theme.deprecated_text2}>
          Optimized Mode
        </ThemedText.DeprecatedBlack>
        <QuestionHelper
          text={
            <Text>
              In Optimized Mode, you may end up with partial WBTC and ETH instead of USDC. You can use the{' '}
              <ExternalLink href={'https://bridge.hydrachain.org'}>bridge.hydrachain.org</ExternalLink> to transfer and
              convert these assets into USDC via present liquidity on Ethereum with very small price impact.
            </Text>
          }
        />
      </RowFixed>
      <Toggle
        id="toggle-optimized-mode-button"
        isActive={optimizedTrade}
        toggle={() => setOptimizedTrade(!optimizedTrade)}
      />
    </RowBetween>
  )
}
