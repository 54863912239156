import { decode } from '@ethersproject/base64'
import { ButtonPrimary } from 'components/Button'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { useHydraAccount, useHydraChainId, useHydraLibrary } from 'hooks/useAddHydraAccExtension'
import { hydraweb3RPC } from 'hooks/useAddHydraAccExtension'
import { DelegatedStakingAbi } from 'hydra/contracts/abi'
import { hydraToHexAddress } from 'hydra/contracts/utils'
import { HYDRA } from 'hydra/sdk'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'
import { Text } from 'rebass'
import { useHydraStakingInfo } from 'state/stake/hooks'
import { useHYDRABalance } from 'state/wallets/hooks'
import styled from 'styled-components/macro'
import { CustomLightSpinner } from 'theme'
import { trimHexPrefix } from 'utils'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import Circle from '../../assets/images/blue-loader.svg'
import ConfirmationModal from './ConfirmationModal'
import {
  CONFIRM_DELEGATE_TEXT,
  CONFIRM_UNDELEGATE_TEXT,
  FEES,
  hydraMainNetURL,
  hydraTestNetURL,
  PENDING_TEXT,
  SORT_KEY_FEES,
  SORT_KEY_UTXO,
  SUPER_STAKER_TABLE_CONFIG,
  UTXO,
} from './constants'
import DetailViewCard from './DetailViewCard'
import { ConfirmationPendingContent } from './SplitUTXO/ConfirmationPending'
import SplitUTXOForm from './SplitUTXO/SplitUTXOForm'
import {
  fetchSuperStakerList,
  getDelegationInfoForAddressApi,
  HYDRA_DELEGATED_STAKING_CONTRACT_ADDRESS,
} from './superStakerApi'
import { getSortedData, SORT_KEY_TYPE } from './utils'

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 15px;
`

const TableHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.75fr 1fr 0.75fr;
  @media (min-width: 0px) and (max-width: 760px) {
    display: none;
  }
  gap: 10px;
  font-weight: bold;
  grid-auto-rows: minmax(60px, auto);
  border-radius: 24px 24px 0 0;
  cursor: pointer;
`

const HeaderContent = styled.div`
  color: ${({ theme }) => theme.deprecated_text1};
  padding: 1rem 1.2rem 1rem 1.2rem;
`

const TableRowForBigScreen = styled.div<{ index?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.75fr 1fr 0.75fr;
  @media (min-width: 0px) and (max-width: 760px) {
    display: none;
  }
  gap: 10px;
  font-weight: bold;
  grid-auto-rows: minmax(60px, auto);
  border-radius: 24px 24px 24px 24px;
  cursor: pointer;
  background: ${({ theme }) => theme.deprecated_advancedBG};
  :hover {
    background: ${({ theme }) => theme.deprecated_bg1};
  }
  border: 0.5px solid grey;
  margin-bottom: 5px;
`

const TableRowForSmllScreen = styled.div<{ index?: number }>`
  width: 100%;
  display: none;
  grid-template-columns: 1fr;
  @media (max-width: 760px) {
    display: grid;
  }
  font-weight: bold;
  grid-auto-rows: minmax(60px, auto);
  border-radius: 24px 24px 24px 24px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.deprecated_bg1};
  }
  border: 0.5px solid grey;
  margin-bottom: 5px;
`

const TableContent = styled.div<{ isBlock?: boolean; justifyContent?: string }>`
  color: ${({ theme }) => theme.deprecated_text1};

  padding: 1rem 1.2rem 1rem 1.2rem;
  @media (max-width: 760px) {
    padding: 0.5rem 1.2rem 0.2rem 1.2rem;
  }
  overflow-wrap: anywhere;
  display: ${({ isBlock }) => (isBlock ? 'block' : 'flex')};
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`

const EmptyStateCard = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListContentWrapperSmall = styled.div`
  display: flex;
`

const ListContentWrapper = styled.div``

const StyledATag = styled.a`
  font-size: 13px;
  opacity: 0.8;
  font-weight: bold;
  text-decoration: none !important;
`

const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.deprecated_bg2};
`

interface ArrowProps {
  ascending?: boolean
  onClick?: any
}

function SortArrow({ ascending, onClick }: ArrowProps) {
  if (ascending) {
    return <ArrowDown height={`16px`} onClick={onClick} />
  } else {
    return <ArrowUp height={`16px`} onClick={onClick} />
  }
}

export default function DelegatedStaking() {
  const [superStakerList, setSuperStakersList] = useState<any>([])
  const [initialCall, setInitialCall] = useState<boolean | null>(true)
  const [apiStatus, setApiStatus] = useState<any>({
    loading: false,
    error: false,
  })
  const [addDelegationApiStatus, setAddDelegationApiStatus] = useState<any>({
    loading: false,
    error: false,
  })

  const [sortByFees, setSortByFees] = useState(false)
  const [sortByUtxo, setSortByUtxo] = useState(false)

  const [extensionForSuperStaker, setExtensionForSuperStaker] = useState<any>({})
  const [hydraContract, setHydraContract] = useState<any | null>()
  const [selectedSuperStaker, setSuperStaker] = useState({
    name: '',
    address: '',
    fees: '',
    isDelegate: false,
  })

  const [currentSelectedStaker, setCurrentSelectedStaker] = useState<any>()
  const [hydraApyPercentage, setHydraApyPercentage] = useState<any>('')
  const [input, setInput] = useState('')
  const [hydraAPY, setHydraApy] = useState('')

  const [hydraweb3Extension] = useHydraLibrary()
  const [chainId] = useHydraChainId()
  const [account] = useHydraAccount()
  const accountHydra = account?.address

  const HYDRABalance = useHYDRABalance(account)[accountHydra ?? '']

  const { apy } = useHydraStakingInfo()
  // Transaction modal state
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const [pendingText, setPendingText] = useState('')
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState('')
  const [transactionErrorMessage, setTransactionErrorMessage] = useState('')
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
  const [delegateParams, setDelegateParams] = useState({ isUnDelegation: false, address: '', fees: '', name: '' })

  ///
  const [isShowUTXOSplitForm, setIsShowUTXOSplitForm] = useState(false)
  const [splitUTXOStatus, setSplitUTXOStatus] = useState({
    isShowModal: false,
    success: false,
    href: '',
    isLoading: false,
    heading: '',
    subHeading: '',
  })

  //

  const superStakerListStringified = useMemo(() => JSON.stringify(superStakerList), [superStakerList])

  useEffect(() => {
    if (hydraweb3Extension && Object.keys(hydraweb3Extension).length) {
      setExtensionForSuperStaker(hydraweb3Extension)
    } else if (hydraweb3RPC && Object.keys(hydraweb3RPC).length) {
      setExtensionForSuperStaker(hydraweb3RPC)
    }
  }, [hydraweb3Extension])

  useEffect(() => {
    if (account !== undefined && account?.loggedIn && extensionForSuperStaker?.provider) {
      setHydraContract(extensionForSuperStaker?.Contract(HYDRA_DELEGATED_STAKING_CONTRACT_ADDRESS, DelegatedStakingAbi))
    }
  }, [account, extensionForSuperStaker])

  const fetchSuperStatkerList = useCallback(async () => {
    try {
      setApiStatus({ loading: true, error: false })
      const superStakerResponseList = await fetchSuperStakerList()
      setSuperStakersList([...superStakerResponseList])
      setApiStatus({ loading: false, error: false })
    } catch (error) {
      setApiStatus({ loading: false, error: true })
      setSuperStakersList([])
    }
  }, [])

  useEffect(() => {
    fetchSuperStatkerList()
  }, [fetchSuperStatkerList])

  const handleDelegateOnClick = useCallback(
    (address: string, fees: any, name: string) => {
      setCurrentSelectedStaker(address)
      if (selectedSuperStaker?.isDelegate && selectedSuperStaker?.address === address) {
        setDelegateParams({ isUnDelegation: true, address: '', fees: '', name })
      } else {
        setDelegateParams({ isUnDelegation: false, address, fees, name })
      }
      setIsShowConfirmModal(true)
      setShowTransactionModal(true)
    },
    [selectedSuperStaker]
  )

  const handleHideConifrmModal = useCallback(() => {
    setIsShowConfirmModal(false)
    setShowTransactionModal(false)
  }, [])

  const getDelegationAddress = useCallback(async () => {
    try {
      const superStakerList = JSON.parse(superStakerListStringified)
      setApiStatus({ loading: true, error: false, success: false, response: {} })
      const response = await getDelegationInfoForAddressApi([accountHydra])
      if (!response.error) {
        const stakerInfo = superStakerList.find((staker: any) => response?.data === staker.address)
        const filteredStakerList = superStakerList.filter((staker: any) => response?.data !== staker.address)
        if (stakerInfo?.address && response?.data) {
          const currentStakerInfo = {
            address: stakerInfo?.address || response?.data,
            name: stakerInfo?.name || 'UNKNOWN',
            fees: stakerInfo?.fees || 0,
          }
          setSuperStakersList([stakerInfo, ...filteredStakerList])
          setSuperStaker({ ...currentStakerInfo, isDelegate: true })
        } else {
          fetchSuperStatkerList()
          setSuperStaker({
            name: '',
            address: '',
            fees: '',
            isDelegate: false,
          })
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setApiStatus({ loading: false, error: false, success: false, response: {} })
    }
  }, [accountHydra, superStakerListStringified, fetchSuperStatkerList])

  useEffect(() => {
    if (accountHydra && initialCall === false) {
      getDelegationAddress()
      setInitialCall(null)
    }
  }, [accountHydra, getDelegationAddress, initialCall])

  useEffect(() => {
    const superStakerList = JSON.parse(superStakerListStringified)
    if (superStakerList?.length && initialCall) {
      getDelegationAddress()
      setInitialCall(false)
    }
  }, [superStakerListStringified, getDelegationAddress, initialCall])

  const checkIfButtonDisabled = useCallback(
    (currentStakerAddress?: string) => {
      let disabled = false
      if (apiStatus?.loading || addDelegationApiStatus?.loading || !accountHydra) {
        disabled = true
      }
      if (selectedSuperStaker.address && selectedSuperStaker.address !== currentStakerAddress) {
        disabled = true
      }

      return disabled
    },
    [apiStatus, addDelegationApiStatus, accountHydra, selectedSuperStaker]
  )

  const buttonDisplayText = useCallback(
    (currentStakerAddress?: string) => {
      let buttonText = 'Delegate'
      if (selectedSuperStaker?.isDelegate && selectedSuperStaker?.address === currentStakerAddress) {
        buttonText = 'Undelegate'
      }
      if (currentSelectedStaker === currentStakerAddress && addDelegationApiStatus?.loading) {
        buttonText = 'Please wait...'
      }
      return buttonText
    },
    [addDelegationApiStatus, currentSelectedStaker, selectedSuperStaker]
  )

  const removeDelegation = useCallback(async () => {
    try {
      setAddDelegationApiStatus({ loading: true, error: false, success: false, response: {} })
      const stake = await hydraContract?.send('removeDelegation', {
        methodArgs: [],
        gasLimit: 100000,
        senderAddress: accountHydra,
      })
      setAddDelegationApiStatus({
        loading: false,
        error: false,
        success: true,
        response: { ...stake },
      })
      setShowTransactionModal(true)
      setHash(stake.txid)
      setAttemptingTxn(true)
      return stake
    } catch (err) {
      setAddDelegationApiStatus({ loading: false, error: true, success: false, response: {} })
      setShowTransactionModal(true)
      setHash('')
      setAttemptingTxn(false)
      //@ts-ignore
      setTransactionErrorMessage((err && err.message) || 'Error in Add Delegation')
    }
  }, [accountHydra, hydraContract])

  const addDelegation = useCallback(
    async (address: any, fees: any) => {
      try {
        const hexStakerAddress = hydraToHexAddress(address)
        let message = trimHexPrefix(hexStakerAddress) ?? ''
        message = await extensionForSuperStaker?.provider.signMessage(message)
        setAddDelegationApiStatus({ loading: true, error: false, success: false, response: {} })
        //the staker address in methodArgs arr must be passed in hex representation

        const stake = await hydraContract?.send('addDelegation', {
          methodArgs: [hexStakerAddress, `${fees}`, decode(message)],
          gasLimit: 250000,
          senderAddress: accountHydra,
        })

        if (stake?.error) {
          throw stake
        }
        setAddDelegationApiStatus({ loading: false, error: false, success: true, response: { ...stake } })
        setShowTransactionModal(true)
        setHash(stake.txid)
        setAttemptingTxn(true)
        return stake
      } catch (err) {
        setAddDelegationApiStatus({ loading: false, error: true, success: false, response: {} })
        setShowTransactionModal(true) // Should be TRUE to show error on modal.
        setHash('')
        setAttemptingTxn(false)
        //@ts-ignore
        setTransactionErrorMessage((err && err.message) || 'Error in Add Delegation')
      }
    },
    [accountHydra, extensionForSuperStaker, hydraContract]
  )

  const handleConfirmButtonClick = useCallback(() => {
    handleHideConifrmModal()
    if (!delegateParams?.isUnDelegation && delegateParams?.address && delegateParams?.fees) {
      addDelegation(delegateParams?.address, delegateParams?.fees)
    } else {
      removeDelegation()
    }
  }, [
    addDelegation,
    delegateParams?.address,
    delegateParams?.fees,
    delegateParams?.isUnDelegation,
    removeDelegation,
    handleHideConifrmModal,
  ])

  const hideTransactionModal = useCallback(() => {
    if (!attemptingTxn) {
      setShowTransactionModal(false)
      setAttemptingTxn(false)
      setHash('')
      setPendingText('')
      setCurrentSelectedStaker('')
      setTransactionErrorMessage('')
      setIsShowConfirmModal(false)
    }
    hideUtxoSplitForm()
  }, [attemptingTxn])

  useEffect(() => {
    const autoRefresh = setInterval(async () => {
      if (attemptingTxn) {
        try {
          const txConfirm = await (await fetch(getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION_API))).json()
          if (txConfirm?.confirmations > 0) {
            setDelegateParams({ isUnDelegation: false, address: '', fees: '', name: '' })
            setAttemptingTxn(false)
            setPendingText('')
            getDelegationAddress()
          } else {
            setPendingText(PENDING_TEXT)
          }
        } catch (error) {}
      }
    }, 3000)

    return () => {
      clearInterval(autoRefresh)
    }
  }, [attemptingTxn, hash, getDelegationAddress, chainId])

  useEffect(() => {
    if (HYDRABalance?.toFixed(8)) {
      const dailyIncome = (parseFloat(HYDRABalance?.toFixed(8)) * parseFloat(hydraAPY)) / 100
      setHydraApyPercentage(dailyIncome.toFixed(4))
      setInput(HYDRABalance?.toFixed(8))
    }
  }, [HYDRABalance, hydraAPY])

  useEffect(() => {
    setHydraApy(apy || `0.00`)
  }, [apy])

  const sortByFeesFunction = useCallback(
    (ascending: boolean) => {
      const superStakerList = JSON.parse(superStakerListStringified)
      const sortedData = getSortedData({
        data: superStakerList,
        type: SORT_KEY_TYPE.NUMBER,
        ascending,
        sortKey: SORT_KEY_FEES,
      })
      setSortByFees(sortedData?.ascending)
      setSuperStakersList([...sortedData?.data])
    },
    [superStakerListStringified]
  )

  const sortByUtxoFunction = useCallback(
    (ascending: boolean) => {
      const superStakerList = JSON.parse(superStakerListStringified)
      const sortedData = getSortedData({
        data: superStakerList,
        type: SORT_KEY_TYPE.NUMBER,
        ascending,
        sortKey: SORT_KEY_UTXO,
      })
      setSortByUtxo(sortedData?.ascending)
      setSuperStakersList([...sortedData?.data])
    },
    [superStakerListStringified]
  )

  ////////////////////////////////////
  const showUtxoSplitForm = () => {
    if (accountHydra) {
      setIsShowUTXOSplitForm(true)
      setShowTransactionModal(true)
    }
  }

  const hideUtxoSplitForm = () => {
    setIsShowUTXOSplitForm(false)
    setShowTransactionModal(false)
    setSplitUTXOStatus({ isShowModal: false, success: false, href: '', isLoading: false, heading: '', subHeading: '' })
  }

  interface splitUtxoProps {
    minUTXOvalue: number
    maxUTXOvalue: number
    maxOutput: number
    delegateAddress: string
  }

  const onClickHandleSplitUTXO = async ({ minUTXOvalue, maxUTXOvalue, maxOutput, delegateAddress }: splitUtxoProps) => {
    try {
      setSplitUTXOStatus({
        ...splitUTXOStatus,
        isShowModal: true,
        isLoading: true,
        heading: 'Please wait...',
        subHeading: '',
      })
      const response = await extensionForSuperStaker?.provider?.splitWalletUTXOS(minUTXOvalue, maxUTXOvalue, maxOutput)
      if (response?.txid) {
        const URL = chainId === 2 ? hydraTestNetURL : hydraMainNetURL
        const href = URL.explorer + response?.txid
        setSplitUTXOStatus({
          ...splitUTXOStatus,
          href,
          subHeading: '',
          success: true,
          heading: 'Completed',
          isShowModal: true,
          isLoading: false,
        })
      } else {
        setSplitUTXOStatus({
          ...splitUTXOStatus,
          href: ``,
          subHeading: typeof response === 'string' ? response : 'No valid UTXOs to split',
          success: true,
          heading: 'Completed',
          isShowModal: true,
          isLoading: false,
        })
      }
    } catch (error) {
      console.error('Error in [onClickHandleSplitUTXO]', error)
      setSplitUTXOStatus({
        ...splitUTXOStatus,
        href: '',
        subHeading: typeof error?.message === 'string' ? error?.message : 'Something went wrong',
        isShowModal: true,
        success: false,
        heading: 'Error',
        isLoading: false,
      })
    } finally {
      setApiStatus({ loading: false, error: false, success: false, response: {} })
    }
  }

  ////////////////////////////////////

  return (
    <>
      <TransactionConfirmationModal
        isOpen={showTransactionModal}
        onDismiss={hideTransactionModal}
        attemptingTxn={attemptingTxn}
        hash={hash}
        content={() => (
          <>
            {isShowConfirmModal ? (
              <ConfirmationModal
                title={`Confirm ${delegateParams?.isUnDelegation ? 'Un-delegate' : 'Delegate'}`}
                onDismiss={handleHideConifrmModal}
                onConfirm={handleConfirmButtonClick}
                modalContentText={`${
                  delegateParams?.isUnDelegation ? CONFIRM_UNDELEGATE_TEXT : CONFIRM_DELEGATE_TEXT
                } ${delegateParams?.name}?`}
              />
            ) : (
              <>
                {isShowUTXOSplitForm ? (
                  <>
                    {splitUTXOStatus?.isShowModal ? (
                      <ConfirmationPendingContent
                        loading={splitUTXOStatus?.isLoading}
                        href={splitUTXOStatus?.href}
                        success={splitUTXOStatus?.success}
                        subHeading={splitUTXOStatus?.subHeading}
                        onDismiss={() => {
                          hideUtxoSplitForm()
                        }}
                        heading={splitUTXOStatus?.heading}
                        title={'SPLIT UTXO'}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onSuccessCallBack={() => {}}
                      />
                    ) : (
                      <>
                        <SplitUTXOForm
                          onDismiss={hideUtxoSplitForm}
                          onConfirm={onClickHandleSplitUTXO}
                          accountHydra={accountHydra}
                        />
                      </>
                    )}
                  </>
                ) : (
                  transactionErrorMessage && (
                    <TransactionErrorContent onDismiss={hideTransactionModal} message={transactionErrorMessage} />
                  )
                )}
              </>
            )}
          </>
        )}
        pendingText={pendingText}
      />
      <DetailViewCard
        accountHydra={accountHydra}
        apy={`${hydraApyPercentage}`}
        apyPercentage={`${hydraAPY}`}
        balance={`${input}`}
        currency={HYDRA}
        onClickSplitUTXO={showUtxoSplitForm}
      />
      <Table style={{ width: '90%' }}>
        <TableHeader>
          {Object.keys(SUPER_STAKER_TABLE_CONFIG).map((item: any, i) => (
            <HeaderContent key={i} style={SUPER_STAKER_TABLE_CONFIG[item]?.style}>
              <span
                onClick={() => {
                  if (SUPER_STAKER_TABLE_CONFIG[item]?.isSort) {
                    SUPER_STAKER_TABLE_CONFIG[item].name === FEES && sortByFeesFunction(sortByFees)
                    SUPER_STAKER_TABLE_CONFIG[item].name === UTXO && sortByUtxoFunction(sortByUtxo)
                  }
                }}
              >
                {SUPER_STAKER_TABLE_CONFIG[item]?.name}
                {SUPER_STAKER_TABLE_CONFIG[item]?.isSort && (
                  <SortArrow ascending={SUPER_STAKER_TABLE_CONFIG[item].name === FEES ? sortByFees : sortByUtxo} />
                )}
              </span>
            </HeaderContent>
          ))}
        </TableHeader>
        <SeparatorLine />
        <>
          {apiStatus?.loading ? (
            <EmptyStateCard>
              <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
            </EmptyStateCard>
          ) : superStakerList?.length ? (
            <>
              {superStakerList.map((staker: any, i: number) => (
                <React.Fragment key={i}>
                  <TableRowForBigScreen>
                    <TableContent isBlock={true}>
                      {staker?.name}
                      <ListContentWrapper>
                        <Text fontSize={13} opacity={0.8} fontWeight={200}>
                          {staker?.address}
                        </Text>

                        {staker?.address && (
                          <Text fontWeight={200} fontSize={13} opacity={0.8}>
                            <StyledATag
                              onClick={(event) => {
                                event.stopPropagation()
                              }}
                              target="_blank"
                              href={`https://explorer.hydrachain.org/address/${staker?.address}/`}
                            >
                              More info..
                            </StyledATag>
                          </Text>
                        )}
                      </ListContentWrapper>
                    </TableContent>
                    <TableContent justifyContent="flex-end" style={{ textAlign: 'right' }}>
                      {`${staker?.fees} %`}
                    </TableContent>
                    <TableContent justifyContent="flex-end" style={{ textAlign: 'right' }}>
                      {`${staker?.utxo} HYDRA`}
                    </TableContent>
                    <TableContent>
                      <ButtonPrimary
                        style={{ borderRadius: '20px!important' }}
                        padding="18px"
                        disabled={checkIfButtonDisabled(staker?.address)}
                        onClick={() => {
                          handleDelegateOnClick(staker?.address, staker?.fees, staker?.name)
                        }}
                      >
                        {buttonDisplayText(staker?.address)}
                      </ButtonPrimary>
                    </TableContent>
                  </TableRowForBigScreen>
                  <TableRowForSmllScreen>
                    <TableContent isBlock={true}>
                      {staker?.name}
                      <ListContentWrapper>
                        <Text fontSize={13} opacity={0.8} fontWeight={200}>
                          {staker?.address}
                        </Text>

                        <ListContentWrapperSmall>
                          <Text fontSize={13} opacity={0.8} fontWeight={'bold'}>{`${FEES}:`}</Text>
                          &nbsp;&nbsp;
                          <Text fontSize={13} opacity={0.8} fontWeight={200}>
                            {`${staker?.fees} %`}
                          </Text>
                        </ListContentWrapperSmall>
                        <ListContentWrapperSmall>
                          <Text fontSize={13} opacity={0.8} fontWeight={'bold'}>{`${UTXO}:`}</Text>
                          &nbsp;&nbsp;
                          <Text fontSize={13} opacity={0.8} fontWeight={200}>
                            {`${staker?.utxo} HYDRA`}
                          </Text>
                        </ListContentWrapperSmall>

                        {staker?.address && (
                          <Text fontWeight={200} fontSize={13} opacity={0.8}>
                            <StyledATag
                              onClick={(event) => {
                                event.stopPropagation()
                              }}
                              target="_blank"
                              href={`https://explorer.hydrachain.org/address/${staker?.address}/`}
                            >
                              More info..
                            </StyledATag>
                          </Text>
                        )}
                      </ListContentWrapper>
                    </TableContent>
                    <TableContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <ButtonPrimary
                        padding="18px"
                        style={{ borderRadius: '20px!important' }}
                        disabled={checkIfButtonDisabled(staker?.address)}
                        onClick={() => {
                          handleDelegateOnClick(staker?.address, staker?.fees, staker?.name)
                        }}
                      >
                        {buttonDisplayText(staker?.address)}
                      </ButtonPrimary>
                    </TableContent>
                  </TableRowForSmllScreen>
                  <SeparatorLine />
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              {apiStatus?.error ? (
                <EmptyStateCard>
                  <Text fontWeight={600}>Oops! Something went wrong!</Text>
                </EmptyStateCard>
              ) : (
                <EmptyStateCard>
                  <Text fontWeight={600}>No Stakers Available</Text>
                </EmptyStateCard>
              )}
            </>
          )}
        </>
      </Table>
    </>
  )
}
