import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Row from 'components/Row'
import { V3_STAKER_ADDRESSES } from 'constants/addresses'
import { Interface } from 'ethers/lib/utils'
import { useHydraChainId, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { V3StakerAbi } from 'hydra/contracts/abi'
import { useV3StakerContract } from 'hydra/hooks/useContract'
import { useCallback, useEffect, useState } from 'react'

interface InputFieldProps {
  label: string
  name: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
}

function InputField({ label, name, onChange }: InputFieldProps) {
  return (
    <div>
      <label>
        {label}: <input name={name} onChange={onChange} />
      </label>
    </div>
  )
}

// This is a demo page for incentive creation. It should be improved in the future.
export default function CreateIncentive() {
  const [account] = useHydraWalletAddress()
  const [chainId] = useHydraChainId()
  const [data, setData] = useState({
    rewardToken: '',
    pool: '',
    startTime: '',
    endTime: '',
    refundee: '',
    reward: '',
  })
  const [, setTokenToApprove] = useState('')

  const handleInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, value } = e.currentTarget
      setData({ ...data, [name]: value })
    },
    [data]
  )

  const contract = useV3StakerContract()

  const handleCreate = useCallback(() => {
    if (Object.values(data).every((x) => x) && contract) {
      try {
        const iface = new Interface(V3StakerAbi)
        const { rewardToken, pool, startTime, endTime, refundee, reward } = data
        const hex = iface
          .encodeFunctionData('createIncentive', [{ rewardToken, pool, startTime, endTime, refundee }, reward])
          .substring(2)

        const txn = [
          contract?.address, // contract address
          hex, // hex
          0, // hydra amount
          250000, // gas limit
          account, // sender address
        ]

        contract?.provider
          ?.rawCall('sendtocontract', txn)
          .then((tx: any) => {
            console.log(tx)
          })
          .catch(console.log)
      } catch (error) {
        console.log(error)
      }
    }
  }, [data, contract, account])

  const handleEnd = useCallback(
    (data: { rewardToken: string; pool: string; startTime: string; endTime: string; refundee: string }) => {
      if (Object.values(data).every((x) => x) && contract) {
        try {
          const iface = new Interface(V3StakerAbi)
          const { rewardToken, pool, startTime, endTime, refundee } = data
          const hex = iface
            .encodeFunctionData('endIncentive', [{ rewardToken, pool, startTime, endTime, refundee }])
            .substring(2)

          const txn = [
            contract?.address, // contract address
            hex, // hex
            0, // hydra amount
            250000, // gas limit
            account, // sender address
          ]

          contract?.provider
            ?.rawCall('sendtocontract', txn)
            .then((tx: any) => {
              console.log(tx)
            })
            .catch(console.log)
        } catch (error) {
          console.log(error)
        }
      }
    },
    [contract, account]
  )

  useEffect(() => {
    handleEnd({
      rewardToken: '2f859738695d1699844ec197359f55c306c4eb74',
      pool: '0x4C968962b531c0205DE0f59A5b4C9D8063e77E82',
      startTime: '1674853200',
      endTime: '1674939600',
      refundee: 'c87d0a7f4539b2088600d8cb4fe92abe2e1bdb92',
    })
  }, [handleEnd])

  // check whether the user has approved the router on the tokens
  const [approval, approveCallback] = useApproveCallback(
    CurrencyAmount.fromRawAmount(new Token(chainId, 'cc74ab8d0e2366956b3cb6d8a8c7f14872c9a987', 8), '1'),
    chainId ? V3_STAKER_ADDRESSES[chainId] : undefined
  )

  return (
    <Row style={{ justifyContent: 'center' }}>
      <AutoColumn gap="md">
        <InputField label={'Reward token'} name={'rewardToken'} onChange={handleInput} />
        <InputField label={'Pool'} name={'pool'} onChange={handleInput} />
        <InputField label={'Start time'} name={'startTime'} onChange={handleInput} />
        <InputField label={'End time'} name={'endTime'} onChange={handleInput} />
        <InputField label={'Refundee'} name={'refundee'} onChange={handleInput} />
        <InputField label={'Reward'} name={'reward'} onChange={handleInput} />
        <ButtonPrimary onClick={handleCreate}>Create</ButtonPrimary>
      </AutoColumn>

      <AutoColumn gap="md">
        <InputField label={'Token'} name={'token'} onChange={(e) => setTokenToApprove(e.currentTarget.value)} />
        <ButtonPrimary disabled={approval !== ApprovalState.NOT_APPROVED} onClick={approveCallback}>
          {approval === ApprovalState.NOT_APPROVED
            ? 'Approve'
            : approval === ApprovalState.PENDING
            ? 'Pending...'
            : 'Approved'}
        </ButtonPrimary>
      </AutoColumn>
    </Row>
  )
}
