import { Trans } from '@lingui/macro'
import Card, { OutlineCard } from 'components/Card'
import { useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { StakingInfo } from 'state/stake/hooks'
import styled, { useTheme } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'

const StyledCard = styled(Card)`
  padding: 0;
`
const WrapperCard = styled(OutlineCard)<{ show: boolean }>`
  padding: 12px;
  max-width: 390px;
  position: absolute;
  left: 50%;
  z-index: -1;
  top: 100%;
  border-top: 0;
  border-radius: 0 0 16px 16px;
  backdrop-filter: blur(5px);
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);

  transform: ${({ show }) => (show ? 'translate(-50%, 0%)' : 'translate(-50%, -100%)')};
  transition: transform 300ms ease-in-out;

  @media (max-width: 720px) {
    margin-top: 0;
  }
`
interface StakingDetailsProps {
  apy: string | null
  apyPercentage: string | null
  stakingInfo: StakingInfo | null
}

export function StakingDetails({ apy, apyPercentage, stakingInfo }: StakingDetailsProps) {
  const theme = useTheme()
  const [account] = useHydraWalletAddress()

  return (
    <WrapperCard backgroundColor={theme.deprecated_advancedBG} show={!!(account && apy)}>
      <StyledCard>
        <AutoColumn gap="8px">
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text2}>
                <Trans>Projected Annual Reward</Trans>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            <ThemedText.DeprecatedMain textAlign="right" fontSize={14}>
              {apy} {stakingInfo?.rewardRate.currency.symbol}
            </ThemedText.DeprecatedMain>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text2}>
                <Trans>APY</Trans>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            <ThemedText.DeprecatedMain textAlign="right" fontSize={14}>
              {apyPercentage}
              {'%'}
            </ThemedText.DeprecatedMain>
          </RowBetween>
        </AutoColumn>
      </StyledCard>
    </WrapperCard>
  )
}
