import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { computePoolAddress, Pool, Position, TickMath } from '@uniswap/v3-sdk'
import { NULL_ADDRESS, V3_CORE_FACTORY_ADDRESSES } from 'constants/addresses'
import { STAKING_V3_POOLS, TESTNET_STAKING_V3_POOLS } from 'constants/v3-staking'
import { useToken } from 'hooks/Tokens'
import { useHydraChainId, useHydraHexAddress, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { usePool } from 'hooks/usePools'
import { useV3Positions } from 'hooks/useV3Positions'
import { useV3StakerPositions } from 'hooks/useV3StakerPositions'
import useWhydraPrice from 'hooks/useWhydraPrice'
import { useV3StakerContract } from 'hydra/hooks/useContract'
import JSBI from 'jsbi'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useEffect, useMemo, useState } from 'react'
import { PositionDetails } from 'types/position'
import { addHexPrefix, areEqualAddresses, trimHexPrefix } from 'utils'

import { priceToSqrt, sqrtToPrice } from './math'
import { getIncentiveId } from './utils'

export function useIncentiveConfig(id: string) {
  return useMemo(
    () => STAKING_V3_POOLS.concat(TESTNET_STAKING_V3_POOLS).find((x) => getIncentiveId(x.key) === id),
    [id]
  )
}

export function useIncentivePositions(incentiveId: string | undefined) {
  const [account] = useHydraWalletAddress()
  const [hexAddr] = useHydraHexAddress()
  const [chainId] = useHydraChainId()

  const incentiveConfig = useIncentiveConfig(incentiveId ?? '')
  const v3StakerContract = useV3StakerContract()

  const { positions, loading: positionsLoading } = useV3Positions(account)
  const { positions: stakingPositions, loading: stakingPositionsLoading } = useV3StakerPositions()

  const allPositions = useMemo(() => {
    let arr: PositionDetails[] = []
    if (stakingPositions) {
      arr = [...arr, ...stakingPositions]
    }

    if (positions) {
      arr = [...arr, ...positions]
    }

    return arr
  }, [positions, stakingPositions])

  const [openPositions] = useMemo(() => {
    return (
      allPositions
        ?.filter((pos) => {
          try {
            const pool1Address = trimHexPrefix(
              computePoolAddress({
                factoryAddress: V3_CORE_FACTORY_ADDRESSES[chainId],
                tokenA: new Token(chainId, pos.token0, 1), // decimals don't matter in this case
                tokenB: new Token(chainId, pos.token1, 1),
                fee: pos.fee,
              })
            )?.toLowerCase()

            const pool2Address = trimHexPrefix(incentiveConfig?.key.pool)?.toLowerCase()

            return areEqualAddresses(pool1Address, pool2Address)
          } catch {
            return false
          }
        })
        .reduce<[PositionDetails[], PositionDetails[]]>(
          (acc, p) => {
            acc[p.liquidity?.isZero() ? 1 : 0].push(p)
            return acc
          },
          [[], []]
        ) ?? [[], []]
    )
  }, [allPositions, incentiveConfig, chainId])

  // get staking info for each nft
  const stakesArgs = useMemo(() => {
    if (openPositions.length && incentiveId) {
      return openPositions.map((pos) => [pos.tokenId.toString(), addHexPrefix(incentiveId)])
    }
    return []
  }, [openPositions, incentiveId])

  const stakes = useSingleContractMultipleData(v3StakerContract, 'stakes', stakesArgs)?.map(({ result }) => {
    return {
      liquidity: result?.liquidity,
      secondsPerLiquidityInsideInitialX128: result?.secondsPerLiquidityInsideInitialX128,
    }
  })

  // get deposit info for each nft
  const depositsArgs = useMemo(() => {
    if (openPositions.length) {
      return openPositions.map((pos) => [pos.tokenId.toString()])
    }
    return []
  }, [openPositions])

  const deposits = useSingleContractMultipleData(v3StakerContract, 'deposits', depositsArgs)?.map(({ result }) => {
    return {
      owner: result?.owner,
      numberOfStakes: result?.numberOfStakes,
    }
  })

  const mappedPositions = useMemo(() => {
    if (openPositions.length && stakes.length && deposits.length) {
      return openPositions
        .map((pos, i) => {
          const { liquidity, secondsPerLiquidityInsideInitialX128 } = stakes[i]
          const { owner, numberOfStakes } = deposits[i]
          return {
            ...pos,
            stakes: { liquidity, secondsPerLiquidityInsideInitialX128 },
            deposits: { owner, numberOfStakes },
          }
        })
        .filter(
          (pos) => areEqualAddresses(pos.deposits.owner, hexAddr) || areEqualAddresses(pos.deposits.owner, NULL_ADDRESS) // TODO check this
        )
    }
    return []
  }, [openPositions, stakes, deposits, hexAddr])

  return { positions: mappedPositions, loading: positionsLoading || stakingPositionsLoading }
}

export enum PositionStakingState {
  NOT_DEPOSITED = 'NOT_DEPOSITED',
  DEPOSITED = 'DEPOSITED',
  STAKED = 'STAKED',
  STAKED_IN_OTHER = 'STAKED_IN_OTHER',
  UNKNOWN = 'UNKNOWN',
}

interface PositionStakingProps {
  owner: string | undefined
  stakingLiquidity: BigNumber | undefined
  numberOfStakes: number | undefined
}
export function usePositionStakingState({ owner, stakingLiquidity, numberOfStakes }: PositionStakingProps) {
  if (owner === undefined || stakingLiquidity === undefined || numberOfStakes === undefined) {
    return PositionStakingState.UNKNOWN
  }

  if (stakingLiquidity.gt(0)) {
    return PositionStakingState.STAKED
  }

  if (numberOfStakes > 0) {
    return PositionStakingState.STAKED_IN_OTHER
  }

  if (!areEqualAddresses(owner, NULL_ADDRESS)) {
    return PositionStakingState.DEPOSITED
  }

  return PositionStakingState.NOT_DEPOSITED
}

interface StakingPosition {
  liquidity: BigNumber
  tickLower: number
  tickUpper: number
}

export function usePositionAPR(positionDetails: StakingPosition | undefined, incentiveId: string | undefined) {
  const [apr, setApr] = useState<null | string>(null)

  const { liquidity, tickLower, tickUpper } = positionDetails ?? {}

  const incentiveConfig = useIncentiveConfig(incentiveId ?? '')
  const rewardToken = useToken(incentiveConfig?.key.rewardToken)
  const reward = useMemo(() => {
    if (rewardToken && incentiveConfig) {
      const durationDays = (Number(incentiveConfig.key.endTime) - Number(incentiveConfig.key.startTime)) / 86400
      const dailyReward = Number(incentiveConfig.reward) / durationDays
      const annualReward = Math.floor(dailyReward * 365.25)

      return CurrencyAmount.fromRawAmount(
        rewardToken ?? undefined,
        parseUnits(annualReward.toString(), rewardToken?.decimals).toString()
      )
    }

    return null
  }, [rewardToken, incentiveConfig])
  const rewardTokenPrice = useWhydraPrice(rewardToken ?? undefined)

  const whydraValueOfReward: CurrencyAmount<Token> | null = useMemo(() => {
    if (!rewardTokenPrice || !reward) return null
    return rewardTokenPrice.quote(reward)
  }, [rewardTokenPrice, reward])

  const token0 = useToken(incentiveConfig?.tokenIdA)
  const token1 = useToken(incentiveConfig?.tokenIdB)

  const [, pool] = usePool(token0 ?? undefined, token1 ?? undefined, incentiveConfig?.fee)
  const position = useMemo(() => {
    if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
      return new Position({
        pool,
        liquidity: liquidity.toString(),
        tickLower,
        tickUpper,
      })
    }
    return undefined
  }, [pool, liquidity, tickLower, tickUpper])

  // whydra prices
  const price0 = useWhydraPrice(pool?.token0 ?? undefined)
  const price1 = useWhydraPrice(pool?.token1 ?? undefined)

  const whydraValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  useEffect(() => {
    if (positionDetails && pool && whydraValueOfReward && whydraValueOfLiquidity && incentiveConfig) {
      const { liquidity: positionLiquidity, tickLower, tickUpper } = positionDetails
      const { liquidity: poolLiquidity, tickCurrent } = pool

      // Check if position is out of range or campaign is inactive
      const timestamp = new Date().getTime() / 1000
      if (
        tickCurrent < tickLower ||
        tickCurrent > tickUpper ||
        timestamp < Number(incentiveConfig.key.startTime) ||
        timestamp > Number(incentiveConfig.key.endTime)
      ) {
        setApr('0.00')
      } else {
        // Position is active
        // trycatch as precaution against overflow
        try {
          const shareBN = BigNumber.from(poolLiquidity.toString()).div(positionLiquidity)
          const share = 1 / shareBN.toNumber()
          const earnProjection = share * Number(whydraValueOfReward.toSignificant(8))
          const apr = (earnProjection / Number(whydraValueOfLiquidity.toSignificant(8))) * 100
          setApr(apr.toFixed(2))
        } catch (error) {
          console.error(error)
        }
      }
    }
  }, [positionDetails, pool, whydraValueOfReward, whydraValueOfLiquidity, incentiveConfig])

  return apr
}

// FeeTier: price modifier
const PRICE_MODIFIERS = {
  100: {
    lower: 0.9921,
    upper: 1.0079,
  },
  500: {
    lower: 0.9949,
    upper: 1.006,
  },
  3000: {
    lower: 0.7,
    upper: 1.4,
  },
  10000: {
    lower: 0.25,
    upper: 2,
  },
}

export function useMidPosition(pool: Pool | null, lower: number | null, upper: number | null) {
  const priceModifier = useMemo(() => {
    if (pool) {
      if (lower && upper) {
        return { lower, upper }
      }
      return PRICE_MODIFIERS[pool?.fee]
    }

    return { lower: 1, upper: 1 }
  }, [pool, lower, upper])

  const tickLower = useMemo(() => {
    try {
      if (!pool) return null

      let tickLower =
        priceModifier.lower !== 1
          ? TickMath.getTickAtSqrtRatio(
              JSBI.BigInt(
                priceToSqrt(
                  sqrtToPrice(pool.sqrtRatioX96, pool.token0.decimals, pool.token1.decimals, 6) * priceModifier.lower,
                  pool.token0.decimals,
                  pool.token1.decimals,
                  8
                )
              )
            )
          : pool.tickCurrent

      if (tickLower >= pool.tickCurrent) {
        tickLower = pool.tickCurrent - pool.tickSpacing
      }

      if (tickLower % pool.tickSpacing !== 0) {
        tickLower -= tickLower % pool.tickSpacing
      }

      if (tickLower >= pool.tickCurrent - pool.tickSpacing) {
        tickLower -= pool.tickSpacing
      }

      return tickLower
    } catch (error) {
      console.error(error)
      return null
    }
  }, [pool, priceModifier])

  const tickUpper = useMemo(() => {
    try {
      if (!pool) return null

      let tickUpper =
        priceModifier.upper !== 1
          ? TickMath.getTickAtSqrtRatio(
              JSBI.BigInt(
                priceToSqrt(
                  sqrtToPrice(pool.sqrtRatioX96, pool.token0.decimals, pool.token1.decimals, 6) * priceModifier.upper,
                  pool.token0.decimals,
                  pool.token1.decimals,
                  8
                )
              )
            )
          : pool.tickCurrent

      if (tickUpper <= pool.tickCurrent) {
        tickUpper = pool.tickCurrent + pool.tickSpacing
      }

      if (tickUpper % pool.tickSpacing !== 0) {
        tickUpper += pool.tickSpacing - (tickUpper % pool.tickSpacing)
      }

      if (tickUpper <= pool.tickCurrent + pool.tickSpacing) {
        tickUpper += pool.tickSpacing
      }

      return tickUpper
    } catch (error) {
      console.error(error)
      return null
    }
  }, [pool, priceModifier])

  const liquidity = pool?.liquidity
    ? BigNumber.from(JSBI.divide(pool?.liquidity, JSBI.BigInt(10)).toString())
    : BigNumber.from('0')

  return liquidity && tickLower && tickUpper ? { liquidity, tickLower, tickUpper } : null
}
