import { Interface } from '@ethersproject/abi'
import {
  DAO_FUNDS_ADDRESSES,
  MULTICALL_ADDRESSES,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  QUOTER_ADDRESSES,
  TICK_LENS_ADDRESSES,
  V3_MIGRATOR_ADDRESSES,
  V3_STAKER_ADDRESSES,
} from 'constants/addresses'
import { LYDRA } from 'constants/routing'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useHydraChainId, useHydraLibrary } from 'hooks/useAddHydraAccExtension'
import {
  AbiHydraV2Pair,
  AbiQuoter,
  AbiQuoterV2,
  AbiToken,
  AbiWrappedHydra,
  DAOFundsAbi,
  LydraAbi,
  NonfungiblePositionManagerAbi,
  StakingTokenAbi,
  TickLensAbi,
  UniMulticallAbi,
  V3MigratorAbi,
  V3StakerAbi,
} from 'hydra/contracts/abi'
import { getContract } from 'hydra/contracts/utils'
import { useMemo } from 'react'

export function useContract(address: string, abi: any[]) {
  const [library] = useHydraLibrary()
  const contract = useMemo(() => library && address && getContract(library, address, abi), [library, address, abi])
  if (contract) {
    contract.interface = new Interface(abi)
  }
  return contract
}

export function useWHYDRAContract() {
  const [chainId] = useHydraChainId()
  return useContract(WRAPPED_NATIVE_CURRENCY[chainId]?.address ?? '', AbiWrappedHydra)
}

export function useV3MigratorContract() {
  const [chainId] = useHydraChainId()
  return useContract(V3_MIGRATOR_ADDRESSES[chainId], V3MigratorAbi)
}

export function useV3MigratorInterface() {
  const iface = useMemo(() => new Interface(V3MigratorAbi), [])
  return iface
}

export function useV2PairContract(address: string | undefined) {
  return useContract(address ?? '', AbiHydraV2Pair)
}

export function useV3NFTPositionManagerContract() {
  const [chainId] = useHydraChainId()
  return useContract(NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId], NonfungiblePositionManagerAbi)
}

export function useMulticallContract() {
  const [chainId] = useHydraChainId()
  return useContract(MULTICALL_ADDRESSES[chainId], UniMulticallAbi)
}

export function useTickLens() {
  const [chainId] = useHydraChainId()
  return useContract(TICK_LENS_ADDRESSES[chainId], TickLensAbi)
}

export function useQuoter(useQuoterV2: boolean) {
  const [chainId] = useHydraChainId()
  return useContract(QUOTER_ADDRESSES[chainId], useQuoterV2 ? AbiQuoterV2 : AbiQuoter)
}

export function useTokenContract(address: string | undefined) {
  return useContract(address ?? '', AbiToken)
}

export function useStakingContract(address: string | undefined) {
  return useContract(address ?? '', StakingTokenAbi)
}

export function useV3StakerContract() {
  const [chainId] = useHydraChainId()
  return useContract(V3_STAKER_ADDRESSES[chainId], V3StakerAbi)
}

export function useLydraContract() {
  const [chainId] = useHydraChainId()
  return useContract(LYDRA[chainId].address, LydraAbi)
}

export function useDAOFundsContract() {
  const [chainId] = useHydraChainId()
  return useContract(DAO_FUNDS_ADDRESSES[chainId], DAOFundsAbi)
}
