import { Trans } from '@lingui/macro'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { ButtonConfirmed, ButtonLight, ButtonPrimary, ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Loader from 'components/Loader'
import { StakingTabs } from 'components/NavigationTabs'
import Row, { RowBetween } from 'components/Row'
import { StakingDetails } from 'components/stake/StakingDetails'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { contractSend } from 'hydra/contracts/utils'
import { useStakingContract } from 'hydra/hooks/useContract'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import AppBody from 'pages/AppBody'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Text } from 'rebass'
import { useConnectHydra, useToggleConnectModal } from 'state/application/hooks'
import { useDerivedStakeInfo, useStakingAPY, useStakingInfoForToken, useStakingTokenConfig } from 'state/stake/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'

import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { StakedAmountButton } from './styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 540px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
    padding: 0px 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.deprecated_bg0};
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

export default function StakeToken() {
  const { tokenIdA } = useParams()
  const theme = useTheme()

  const toggleConnectModal = useToggleConnectModal()
  const connectHydra = useConnectHydra()

  const connectWallet = useCallback(() => {
    toggleConnectModal()
    connectHydra()
  }, [toggleConnectModal, connectHydra])

  const [account] = useHydraWalletAddress()
  const token = useToken(tokenIdA)
  const tokenBalance = useTokenBalance(account ?? undefined, token || undefined)

  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount } = useDerivedStakeInfo(typedValue, token || undefined, tokenBalance)

  // Set value to 1 if undefined
  const amountString = useMemo(
    () => (token && parsedAmount ? formatUnits(parsedAmount?.quotient.toString(), token?.decimals) : '1'),
    [parsedAmount, token]
  )

  const stakingConfig = useStakingTokenConfig(token?.address)
  const stakingInfo = useStakingInfoForToken(token)
  const { apy, apyPercentage } = useStakingAPY(parseUnits(amountString, token?.decimals), token || undefined)

  // pass 1 currency amount if parsedAmount is undefined
  const [approval, approveCallback] = useApproveCallback(
    parsedAmount ||
      (token ? CurrencyAmount.fromRawAmount(token, parseUnits('1', token.decimals).toString()) : undefined),
    stakingInfo?.stakingRewardAddress
  )

  const handleApprove = useCallback(async () => {
    await approveCallback()
  }, [approveCallback])

  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  const maxAmountInput = maxAmountSpend(tokenBalance)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()

  const [showConfirm, setShowConfirm] = useState(false)
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const [txError, setTxError] = useState('')
  const [pendingText, setPendingText] = useState('')

  const wrappedOnDismiss = useCallback(() => {
    setShowConfirm(false)
    setHash(undefined)
    setAttempting(false)
    setPendingText('')
    setTxError('')
  }, [])

  const stakingContract = useStakingContract(stakingInfo?.stakingRewardAddress)

  const onStake = useCallback(() => {
    if (stakingContract && parsedAmount && account) {
      if (approval === ApprovalState.APPROVED) {
        setShowConfirm(true)
        setAttempting(true)
        setPendingText(
          `Stake ${formatUnits(parsedAmount?.quotient.toString(), parsedAmount?.currency.decimals)} ${
            parsedAmount.currency.symbol
          }`
        )

        contractSend(stakingContract, 'stake', [`0x${parsedAmount.quotient.toString(16)}`], account, 250000)
          .then((response) => {
            response.hash = response.id

            addTransaction(response, {
              type: TransactionType.STAKE_TOKEN,
              tokenAddress: parsedAmount.currency.address,
            })

            setShowConfirm(true)
            setHash(response.hash)
            setAttempting(false)
          })
          .catch((error) => {
            setShowConfirm(true)
            setTxError(error?.message)
            setAttempting(false)
            console.log(error)
          })
      } else {
        setPendingText('')
        setAttempting(false)
        throw new Error('Attempting to stake without approval.')
      }
    }
  }, [account, approval, parsedAmount, stakingContract, addTransaction])

  const onUnstake = useCallback(() => {
    if (stakingContract && parsedAmount && account) {
      setShowConfirm(true)
      setAttempting(true)
      setPendingText(
        `Unstake ${formatUnits(parsedAmount?.quotient.toString(), parsedAmount?.currency.decimals)} ${
          parsedAmount.currency.symbol
        }`
      )

      contractSend(stakingContract, 'unstake', [`0x${parsedAmount.quotient.toString(16)}`], account, 250000)
        .then((response) => {
          response.hash = response.id

          addTransaction(response, {
            type: TransactionType.UNSTAKE_TOKEN,
            tokenAddress: parsedAmount.currency.address,
          })

          setHash(response.hash)
          setAttempting(false)
        })
        .catch((error) => {
          setTxError(error?.message)
          setAttempting(false)
          console.log(error)
        })
    }
  }, [account, parsedAmount, stakingContract, addTransaction])

  const onRestake = useCallback(() => {
    if (stakingContract && account && stakingInfo) {
      setShowConfirm(true)
      setAttempting(true)
      setPendingText(
        `Restake ${formatUnits(
          stakingInfo.earnedAmount.quotient.toString(),
          stakingInfo.earnedAmount?.currency.decimals
        )} ${stakingInfo.earnedAmount.currency.symbol}`
      )

      contractSend(stakingContract, 'reinvest', [], account, 250000)
        .then((response) => {
          response.hash = response.id

          addTransaction(response, {
            type: TransactionType.RESTAKE_TOKEN,
            tokenAddress: stakingInfo.earnedAmount.currency.address,
          })

          setHash(response.hash)
          setAttempting(false)
        })
        .catch((error) => {
          setTxError(error?.message)
          setAttempting(false)
          console.log(error)
        })
    }
  }, [account, stakingInfo, stakingContract, addTransaction])

  const onWithdraw = useCallback(() => {
    if (stakingContract && account && stakingInfo) {
      setShowConfirm(true)
      setAttempting(true)
      setPendingText(
        `Withdraw ${formatUnits(
          stakingInfo.earnedAmount.quotient.toString(),
          stakingInfo.earnedAmount?.currency.decimals
        )} ${stakingInfo.earnedAmount.currency.symbol}`
      )

      contractSend(stakingContract, 'getReward', [], account, 250000)
        .then((response) => {
          response.hash = response.id

          addTransaction(response, {
            type: TransactionType.WITHDRAW_STAKING_REWARD,
            tokenAddress: stakingInfo.earnedAmount.currency.address,
          })

          setHash(response.hash)
          setAttempting(false)
        })
        .catch((error) => {
          setTxError(error?.message)
          setAttempting(false)
          console.log(error)
        })
    }
  }, [account, stakingInfo, stakingContract, addTransaction])

  return (
    <Trace page={PageName.STAKE_TOKEN_PAGE} shouldLogImpression>
      <>
        <AppBody>
          <StakingTabs />
          <PageWrapper>
            <TransactionConfirmationModal
              isOpen={showConfirm}
              onDismiss={wrappedOnDismiss}
              attemptingTxn={attempting}
              hash={hash}
              pendingText={pendingText}
              content={() => txError && <TransactionErrorContent onDismiss={wrappedOnDismiss} message={txError} />}
            />
            <AutoColumn gap="lg" justify="center">
              <AutoColumn gap="lg" style={{ width: '100%' }}>
                <MainContentWrapper>
                  <CurrencyInputPanel
                    id={'stake-input-field'}
                    value={typedValue}
                    currency={token}
                    showCurrencyAmount={true}
                    showMaxButton={!atMaxAmount}
                    onUserInput={onUserInput}
                    onMax={handleMaxInput}
                  />
                  {account ? (
                    <>
                      <Row justify="start">
                        <StakedAmountButton
                          onClick={() =>
                            onUserInput(
                              formatUnits(stakingInfo?.stakedAmount.quotient.toString() ?? '0', token?.decimals)
                            )
                          }
                        >
                          Staked balance: {stakingInfo ? stakingInfo?.stakedAmount.toFixed(4) : '-'}
                        </StakedAmountButton>
                      </Row>

                      <AutoColumn gap="md">
                        <RowBetween marginTop="16px">
                          {approval === ApprovalState.APPROVED ? (
                            <>
                              <ButtonPrimary
                                $borderRadius="20px"
                                width={'48%'}
                                fontSize={'18px'}
                                disabled={!parsedAmount || !tokenBalance || tokenBalance?.lessThan(parsedAmount)}
                                onClick={onStake}
                                padding="12px"
                              >
                                {parsedAmount && (!tokenBalance || tokenBalance?.lessThan(parsedAmount))
                                  ? 'Insufficient balance'
                                  : 'Stake'}
                              </ButtonPrimary>

                              <ButtonSecondary
                                $borderRadius="20px"
                                width={'48%'}
                                fontSize={'18px'}
                                disabled={
                                  !parsedAmount ||
                                  !stakingInfo?.stakedAmount ||
                                  stakingInfo?.stakedAmount?.lessThan(parsedAmount)
                                }
                                onClick={onUnstake}
                                padding="12px"
                              >
                                {parsedAmount &&
                                (!stakingInfo?.stakedAmount || stakingInfo?.stakedAmount?.lessThan(parsedAmount))
                                  ? 'Insufficient balance'
                                  : 'Unstake'}
                              </ButtonSecondary>
                            </>
                          ) : (
                            <ButtonConfirmed
                              onClick={handleApprove}
                              disabled={approval !== ApprovalState.NOT_APPROVED}
                              altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                              width={'100%'}
                              fontSize={'18px'}
                              padding="12px"
                            >
                              {approval === ApprovalState.PENDING ? <Loader stroke="white" /> : <Trans>Approve</Trans>}
                            </ButtonConfirmed>
                          )}
                        </RowBetween>
                      </AutoColumn>
                      <AutoColumn gap="md" style={{ marginTop: '24px', borderTop: '1px solid #2C2F36' }}>
                        <Row justify="center" marginTop="16px">
                          <Text textAlign="center" color={theme.deprecated_text2} fontSize={'16px'} fontWeight={'500'}>
                            Current profit: {stakingInfo ? stakingInfo?.earnedAmount.toFixed(4) : '-'}{' '}
                            {stakingInfo?.rewardRate.currency.symbol}
                          </Text>
                        </Row>
                        <Row justify="center">
                          {stakingConfig?.stakingToken.address === stakingConfig?.rewardToken.address && (
                            <ButtonLight
                              disabled={!!(stakingInfo && stakingInfo?.earnedAmount.equalTo('0'))}
                              padding={'4px'}
                              $borderRadius={'20px'}
                              width={'120px'}
                              marginRight={'8px'}
                              onClick={onRestake}
                            >
                              Restake
                            </ButtonLight>
                          )}
                          <ButtonLight
                            disabled={!!(stakingInfo && stakingInfo?.earnedAmount.equalTo('0'))}
                            padding={'4px'}
                            $borderRadius={'20px'}
                            width={'120px'}
                            onClick={onWithdraw}
                          >
                            Withdraw
                          </ButtonLight>
                        </Row>
                      </AutoColumn>
                    </>
                  ) : (
                    <ButtonLight onClick={connectWallet} marginTop={'16px'}>
                      <Trans>Connect Wallet</Trans>
                    </ButtonLight>
                  )}
                </MainContentWrapper>
              </AutoColumn>
            </AutoColumn>
          </PageWrapper>
          <StakingDetails apy={apy} apyPercentage={apyPercentage} stakingInfo={stakingInfo} />
        </AppBody>
      </>
    </Trace>
  )
}
