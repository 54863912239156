// used to mark unsupported tokens, these are hosted lists of unsupported tokens
/**
 * @TODO add list from blockchain association
 */

const STAKING_ONLY_LIST =
  'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/staking-only-list.json'
export const UNSUPPORTED_LIST_URLS: string[] = [STAKING_ONLY_LIST]

const HYDRASWAP_LIST = 'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/hydraswap-list.json'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  HYDRASWAP_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [HYDRASWAP_LIST]
