import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useState } from 'react'

const defaultState = {
  showConfirm: false,
  attempting: false,
  hash: '',
  txError: '',
  pendingText: '',
}

interface TransactionConfirmationContextType {
  transactionData: typeof defaultState
  setTransactionData: Dispatch<SetStateAction<typeof defaultState>>
}

export const TransactionConfirmationContext = createContext<TransactionConfirmationContextType>({
  transactionData: defaultState,
  setTransactionData: (state: SetStateAction<typeof defaultState>) => undefined,
})

export const TransactionConfirmationProvider = ({ children }: PropsWithChildren) => {
  const [transactionData, setTransactionData] = useState(defaultState)

  const onDismiss = useCallback(() => {
    setTransactionData(defaultState)
  }, [])

  return (
    <TransactionConfirmationContext.Provider value={{ transactionData, setTransactionData }}>
      <TransactionConfirmationModal
        isOpen={transactionData.showConfirm}
        onDismiss={onDismiss}
        attemptingTxn={transactionData.attempting}
        hash={transactionData.hash}
        pendingText={transactionData.pendingText}
        content={() =>
          transactionData.txError && (
            <TransactionErrorContent onDismiss={onDismiss} message={formatErrorMessage(transactionData.txError)} />
          )
        }
      />
      {children}
    </TransactionConfirmationContext.Provider>
  )
}

function formatErrorMessage(err: string) {
  let newMsg = err
  if (err.includes('413')) {
    newMsg = `Too many input UTXO's, please reduce the amount or perform multiple transactions of lower amounts - code 413`
  } else if (err === 'could not find UTXOs to build transaction') {
    newMsg = 'Error building transaction due to UTXO unavailability, please try with a lesser amount'
  } else if (err === 'mandatory-script-verify-flag-failed (Signature is found in scriptCode) (code 16)') {
    newMsg = 'Please use a non-staking wallet for DEX activities - code 16'
  }

  return newMsg
}
