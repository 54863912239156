import axios from 'axios'

export const HYDRA_DELEGATED_STAKING_CONTRACT_ADDRESS = '0000000000000000000000000000000000000094'

const GET_DELEGATION_INFO_API_URL =
  'https://ql4asp4scl.execute-api.eu-central-1.amazonaws.com/getdelegationinfoforaddress'
const HYDRA_INFO_API_URL = 'https://explorer.hydrachain.org/api/info'

export const SUPER_STAKER_JSON_LIST =
  'https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/super-staker-list.json'

export const CURRENCY_NAME_HYDRA = 'hydra'

export const getDelegationInfoForAddressApi = async (params: any) => {
  try {
    const response = await axios.post(GET_DELEGATION_INFO_API_URL, { method: 'getdelegationinfoforaddress', params })
    return { data: response?.data?.result?.staker || '', error: false, message: '' }
  } catch (error) {
    //@ts-ignore
    console.error('ERROR->[GetDelegationInfoForAddressApi]', error?.message)
    //@ts-ignore
    return { data: '', error: true, message: error && error?.message }
  }
}

export const getHydraInfo = async () => {
  try {
    const { data: hydraInfo } = await axios.get(HYDRA_INFO_API_URL)
    return { data: hydraInfo, error: false, message: '' }
  } catch (error) {
    //@ts-ignore
    console.error('ERROR->[getHydraInfo]', error?.message)
    //@ts-ignore
    return { hydraApy: '', error: true, message: error && error?.message }
  }
}

export const fetchSuperStakerList = async () => {
  try {
    const { data: response } = await axios.get(SUPER_STAKER_JSON_LIST)
    return response
  } catch (error) {
    //@ts-ignore
    console.error('ERROR->[fetchSuperStakerList]', error?.message)
    return []
  }
}
