import { Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { ETH, ETH_TESTNET, USDC, USDC_TESTNET, WBTC, WBTC_TESTNET } from 'constants/routing'
import { useMemo } from 'react'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { useOptimizedTrade } from 'state/user/hooks'

import { useHydraChainId } from './useAddHydraAccExtension'
import { useClientSideV3Trade } from './useClientSideV3Trade'

const HARDCODED_SPLIT_SETTINGS = {
  2: {
    usdc: 40,
    wbtc: 30,
    eth: 30,
  },
  5: {
    usdc: 34,
    wbtc: 33,
    eth: 33,
  },
  10: {
    usdc: 20,
    wbtc: 40,
    eth: 40,
  },
}

/**
 * Returns a split route trade for a  Hydra->USDC swap
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useHydraUsdcSplitTrade<TTradeType extends TradeType>(
  tradeType: TTradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency,
  mainTradePriceImpact?: number
): { state: TradeState; trade: InterfaceTrade<Currency, Currency, TTradeType> | undefined } {
  const [chainId] = useHydraChainId()
  const [optimizedTrade] = useOptimizedTrade()

  const priceImpactFilter = useMemo(() => {
    if (!mainTradePriceImpact) return 2
    if (mainTradePriceImpact >= 10) {
      return 10
    } else if (mainTradePriceImpact >= 5) {
      return 5
    }
    return 2
  }, [mainTradePriceImpact])

  const usdcPercentage = new Percent(HARDCODED_SPLIT_SETTINGS[priceImpactFilter].usdc, 100)
  const wbtcPercentage = new Percent(HARDCODED_SPLIT_SETTINGS[priceImpactFilter].wbtc, 100)
  const ethPercentage = new Percent(HARDCODED_SPLIT_SETTINGS[priceImpactFilter].eth, 100)

  const usdcTrade = useClientSideV3Trade(
    tradeType,
    amountSpecified?.multiply(usdcPercentage),
    otherCurrency && chainId === otherCurrency.chainId ? (chainId === 1 ? USDC : USDC_TESTNET) : undefined,
    1
  )
  const wbtcTrade = useClientSideV3Trade(
    tradeType,
    amountSpecified?.multiply(wbtcPercentage),
    otherCurrency && chainId === otherCurrency.chainId ? (chainId === 1 ? WBTC : WBTC_TESTNET) : undefined,
    1
  )
  const ethTrade = useClientSideV3Trade(
    tradeType,
    amountSpecified?.multiply(ethPercentage),
    otherCurrency && chainId === otherCurrency.chainId ? (chainId === 1 ? ETH : ETH_TESTNET) : undefined,
    1
  )

  const tradeState = useMemo(() => {
    const states = [usdcTrade.state, wbtcTrade.state, ethTrade.state]
    if (states.every((state) => state === TradeState.VALID)) {
      return TradeState.VALID
    }
    if (states.some((state) => state === TradeState.INVALID)) {
      return TradeState.INVALID
    }
    if (states.some((state) => state === TradeState.NO_ROUTE_FOUND)) {
      return TradeState.NO_ROUTE_FOUND
    }

    return TradeState.LOADING
  }, [usdcTrade, wbtcTrade, ethTrade])

  const trade = useMemo(() => {
    if (usdcTrade.trade && wbtcTrade.trade && ethTrade.trade) {
      const trade: any = usdcTrade.trade
      trade.extraTrades = [wbtcTrade?.trade, ethTrade?.trade]

      return trade
    }
  }, [usdcTrade, wbtcTrade, ethTrade])

  if (
    !optimizedTrade ||
    tradeType === TradeType.EXACT_OUTPUT ||
    amountSpecified?.currency.wrapped.symbol !== 'WHYDRA' ||
    otherCurrency?.wrapped.symbol !== 'USDC' ||
    mainTradePriceImpact === undefined ||
    mainTradePriceImpact < 2
  ) {
    return {
      state: TradeState.INVALID,
      trade: undefined,
    }
  }

  return {
    state: tradeState,
    trade,
  }
}
