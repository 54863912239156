import { Trans } from '@lingui/macro'
import StakePositionListItem from 'components/StakePositionListItem/StakePositionListItem'
import React from 'react'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { StakingPositionDetails } from 'types/position'

const DesktopHeader = styled.div`
  display: none;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & > div:last-child {
      text-align: right;
      margin-right: 12px;
    }
  }
`

const MobileHeader = styled.div`
  font-weight: medium;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: none;
  }

  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`

type StakePositionListProps = React.PropsWithChildren<{
  positions: StakingPositionDetails[]
  incentiveId: string
}>

export default function StakePositionList({ positions, incentiveId }: StakePositionListProps) {
  return (
    <>
      <DesktopHeader>
        <div>
          <Trans>Your positions</Trans>
          {positions && ' (' + positions.length + ')'}
        </div>
      </DesktopHeader>
      <MobileHeader>
        <Trans>Your positions</Trans>
      </MobileHeader>
      {positions.map((p) => {
        return <StakePositionListItem key={p.tokenId.toString()} positionDetails={p} incentiveId={incentiveId} />
      })}
    </>
  )
}
