import { Interface, keccak256 } from 'ethers/lib/utils'
import { V3StakerAbi } from 'hydra/contracts/abi'

interface IncentiveData {
  rewardToken: string
  pool: string
  startTime: string
  endTime: string
  refundee: string
}

export function getIncentiveKeyEncoded(data: IncentiveData) {
  const iface = new Interface(V3StakerAbi)
  const hex = iface.encodeFunctionData('endIncentive', [data]).substring(10)

  return '0x' + hex
}

export function getIncentiveId(data: IncentiveData) {
  const hex = getIncentiveKeyEncoded(data)

  const id = keccak256(hex)
  return id.substring(2)
}

export function formatIncentiveTimestamp(timestamp: string | undefined) {
  return timestamp
    ? new Date(Number(timestamp) * 1000)
        .toLocaleString('en-GB', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
        .toUpperCase()
    : ''
}
