import { Trans } from '@lingui/macro'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import { Checkbox } from 'components/SearchModal/styleds'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import CustomInputPanel from './CustomInputPanel'

const Section = styled(AutoColumn)`
  padding: 24px 24px 0px 0px;
  height: 250px;
  overflow: auto;
`

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-top: 1rem;
`

export default function SplitUTXOForm({
  onDismiss,
  onConfirm,
  accountHydra,
}: {
  onDismiss: () => void
  onConfirm: (arg: any) => void
  accountHydra: string
}) {
  const theme = useTheme()

  const MAX_UTXO_MIN_VALUE = 200
  const MAX_UTXO_MAX_CHAR = 20

  const [maxUTXOvalue, setMaxValue] = useState(`200`)
  const [isIncludeBelowUTXO, setIsIncludeBelowUTXO] = useState(false)

  const CONFIGURATIONS = {
    default: {
      minUTXO: 0.001,
      splitAbove: maxUTXOvalue,
      outputUTXO: 100,
    },
    includeBelow100: {
      minUTXO: 100,
      splitAbove: maxUTXOvalue,
      outputUTXO: 100,
    },
  }

  const validateMaxValue = () => {
    return (
      parseFloat(maxUTXOvalue) >= MAX_UTXO_MIN_VALUE && maxUTXOvalue !== '' && maxUTXOvalue.length <= MAX_UTXO_MAX_CHAR
    )
  }

  const checkIsSubmitDisabled = () => {
    return !validateMaxValue() || !accountHydra
  }

  const onClickSplitUTXO = () => {
    if (!checkIsSubmitDisabled()) {
      const selectedConfig: keyof typeof CONFIGURATIONS = isIncludeBelowUTXO ? 'includeBelow100' : 'default'
      const { minUTXO, splitAbove, outputUTXO } = CONFIGURATIONS[selectedConfig]
      onConfirm &&
        onConfirm({
          minUTXOvalue: minUTXO,
          maxUTXOvalue: parseFloat(splitAbove),
          maxOutput: outputUTXO,
          delegateAddress: accountHydra,
        })
    }
  }

  return (
    <>
      <ConfirmationModalContent
        title={'Split UTXO'}
        bottomSectionPadding={'0px'}
        bottomContent={() => (
          <BottomSection>
            <ButtonSecondary padding="18px" onClick={onDismiss} width={'50%'}>
              Cancel
            </ButtonSecondary>
            <ButtonPrimary disabled={checkIsSubmitDisabled()} padding="18px" onClick={onClickSplitUTXO} width={'50%'}>
              Split Coins
            </ButtonPrimary>
          </BottomSection>
        )}
        onDismiss={onDismiss}
        topContent={() => (
          <>
            <Section>
              <AutoColumn gap="15px" justify={'center'}>
                <AutoColumn gap="lg" style={{ width: '100%' }}>
                  <AutoColumn gap="md" style={{ width: '100%', paddingBottom: '10px' }}>
                    <CustomInputPanel
                      label={'Delegated Address'}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onError={() => {}}
                      value={accountHydra || ''}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChange={() => {}}
                      disabled={true}
                    ></CustomInputPanel>
                    <CustomInputPanel
                      label={'Split UTXOs(HYDRA) above:'}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onError={() => {}}
                      isError={!validateMaxValue()}
                      value={maxUTXOvalue}
                      placeHolder={`0.00`}
                      isTextInput={false}
                      onChange={(val) => {
                        setMaxValue(val)
                      }}
                      helperText={
                        maxUTXOvalue?.length > MAX_UTXO_MAX_CHAR
                          ? `Maximum character limit: ${MAX_UTXO_MAX_CHAR} exceeded`
                          : `Split UTXOs should be minimum ${MAX_UTXO_MIN_VALUE} HYDRA`
                      }
                    ></CustomInputPanel>
                    <AutoRow
                      justify="flex-start"
                      style={{ cursor: 'pointer', paddingLeft: '5px' }}
                      onClick={() => {
                        setIsIncludeBelowUTXO(!isIncludeBelowUTXO)
                      }}
                    >
                      <Checkbox
                        name="confirmed"
                        type="checkbox"
                        checked={isIncludeBelowUTXO}
                        onChange={() => {
                          setIsIncludeBelowUTXO(!isIncludeBelowUTXO)
                        }}
                      />
                      <ThemedText.DeprecatedBody
                        ml="10px"
                        fontSize="16px"
                        color={theme.deprecated_text4}
                        fontWeight={500}
                      >
                        <Trans>Include UTXOs below 100</Trans>
                      </ThemedText.DeprecatedBody>
                    </AutoRow>
                  </AutoColumn>
                </AutoColumn>
              </AutoColumn>
            </Section>
          </>
        )}
      />
    </>
  )
}
