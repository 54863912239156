import { Trans } from '@lingui/macro'
import AbiInputPanel from 'components/AbiInputPanel'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import { NULL_ADDRESS } from 'constants/addresses'
import { useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { useCallback, useMemo, useState } from 'react'
import { useConnectHydra, useToggleConnectModal } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { Dropdown } from './Dropdown'
import { StyledTabOption, Tabs } from './styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
    padding: 0px 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const TitleRow = styled(Row)`
  justify-content: center;
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.deprecated_bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export enum AbiMethodsTab {
  READ = 'READ',
  WRITE = 'WRITE',
}

interface AbiInputOutput {
  name: string
  type: string
}

export interface AbiMethod {
  constant: boolean
  inputs: AbiInputOutput[]
  name: string
  outputs: AbiInputOutput[]
  payable: boolean
  stateMutability: string
  type: string
}

export default function AbiPlayer() {
  const [account] = useHydraWalletAddress()
  const [address, setAddress] = useState('')
  const [abi, setAbi] = useState('')
  const [tab, setTab] = useState(AbiMethodsTab.READ)

  const toggleConnectModal = useToggleConnectModal()
  const connectHydra = useConnectHydra()

  const connectWallet = useCallback(() => {
    toggleConnectModal()
    connectHydra()
  }, [toggleConnectModal, connectHydra])

  const [readFunctions, writeFunctions] = useMemo(() => {
    try {
      const parsedAbi = JSON.parse(abi)
      if (!Array.isArray(parsedAbi)) {
        throw new Error('Invalid Abi')
      }

      return parsedAbi.reduce(
        (acc, cur) => {
          if (cur.type === 'function') {
            if (cur.hasOwnProperty('constant')) {
              if (cur.constant) {
                acc[0].push(cur)
              } else {
                acc[1].push(cur)
              }
            } else {
              if (cur.stateMutability === 'pure' || cur.stateMutability === 'view') {
                acc[0].push(cur)
              } else {
                acc[1].push(cur)
              }
            }
          }
          return acc
        },
        [[], []]
      )
    } catch {
      return [[], []]
    }
  }, [abi])

  const isAbi = useMemo(() => {
    try {
      const parsedAbi = JSON.parse(abi)
      return Array.isArray(parsedAbi)
    } catch {
      return false
    }
  }, [abi])

  return (
    <PageWrapper>
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
            <ThemedText.LargeHeader textAlign={'center'}>
              <Trans>Abi Player</Trans>
            </ThemedText.LargeHeader>
          </TitleRow>

          <MainContentWrapper>
            <AutoColumn gap="md" style={{ width: '420px', fontSize: '14px' }}>
              <AddressInputPanel
                value={address}
                onChange={(x) => setAddress(x)}
                label={'Contract address'}
                placeholder={NULL_ADDRESS}
              />

              <AbiInputPanel
                value={abi}
                onChange={(x) => {
                  try {
                    setAbi(JSON.stringify(JSON.parse(x)))
                  } catch {
                    setAbi(x)
                  }
                }}
                label={'Abi'}
                placeholder={'[]'}
              />
            </AutoColumn>

            <Tabs>
              <RowBetween style={{ padding: '1rem 1rem 0 1rem', position: 'relative', gap: '8px' }}>
                <StyledTabOption
                  className={tab === AbiMethodsTab.READ ? 'active' : ''}
                  onClick={() => setTab(AbiMethodsTab.READ)}
                >
                  Read
                </StyledTabOption>
                <StyledTabOption
                  className={tab === AbiMethodsTab.WRITE ? 'active' : ''}
                  onClick={() => setTab(AbiMethodsTab.WRITE)}
                >
                  Write
                </StyledTabOption>
              </RowBetween>
            </Tabs>

            {isAbi && (
              <Dropdown
                options={tab === AbiMethodsTab.READ ? readFunctions : writeFunctions}
                type={tab}
                abi={isAbi ? JSON.parse(abi) : []}
                address={address}
              />
            )}

            {!account && (
              <ButtonPrimary
                maxWidth={'300px'}
                style={{ marginTop: '2em', padding: '8px 16px' }}
                onClick={connectWallet}
              >
                <Trans>Connect a wallet</Trans>
              </ButtonPrimary>
            )}
          </MainContentWrapper>
        </AutoColumn>
      </AutoColumn>
    </PageWrapper>
  )
}
