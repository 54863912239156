import { hydraToHexAddress } from 'hydra/contracts/utils'
import { ChainId } from 'hydra/sdk'
import { getChainId } from 'hydra/utils'
import { useMemo } from 'react'

import useHydra from './useHydra'

export function isEmptyObj(obj: Record<string, any>) {
  if (!obj) {
    return true
  }
  return Object.keys(obj).length === 0
}

export interface Account {
  address: string
  balance: string
  loggedIn: boolean
  name: string
  network: string
}

export let account: Account
export let hydraweb3RPC: any

export default function useAddHydraAccExtension(walletExtension: any, hydraweb3Extension: any) {
  if (!isEmptyObj(walletExtension)) {
    account = walletExtension
  }
  if (!isEmptyObj(hydraweb3Extension)) {
    hydraweb3RPC = hydraweb3Extension
  }
}

export function useHydraLibrary() {
  const { hydraweb3Extension } = useHydra()
  const isEmpty = isEmptyObj(hydraweb3Extension)

  return [isEmpty ? undefined : hydraweb3Extension]
}

export function useHydraAccount() {
  const { walletExtension } = useHydra()
  return [walletExtension]
}

export function useHydraWalletAddress() {
  const [account] = useHydraAccount()
  return [account?.address || undefined]
}

export function useHydraHexAddress(noPrefix?: boolean | undefined) {
  const [addr] = useHydraWalletAddress()
  const hexAddr = useMemo(() => addr && hydraToHexAddress(addr, noPrefix), [addr, noPrefix])
  return [hexAddr]
}

export function useHydraChainId(): ChainId[] {
  const [account] = useHydraAccount()
  return [getChainId(account?.network) ?? ChainId.MAINNET]
}
