import { HYDRA_INFO_API_URL, V2_DEX_GRAPH_ENDPOINT } from 'constants/index'

export function compoundInterest(
  principal: number,
  time: number,
  rate: number,
  numberOfCompoundingsPerTime: number
): number {
  const amount = principal * Math.pow(1 + rate / numberOfCompoundingsPerTime, numberOfCompoundingsPerTime * time)
  const interest = amount - principal
  return interest
}

export async function getPairPriceHydra(pairAddress: string | undefined) {
  const res = await (
    await fetch(V2_DEX_GRAPH_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `{
          pairs (where: { id_in: ${JSON.stringify([pairAddress])} }) {
            token0 {
              derivedHYDRA
            }
            token1 {
              derivedHYDRA
            }
            reserve0
            reserve1
            id
            totalSupply
          }
        }`,
      }),
    })
  ).json()

  if (res.errors) {
    throw res.errors[0].message
  }

  if (res.data?.pairs?.length) {
    const pair = res.data.pairs[0]
    const { token0, token1, reserve0, reserve1 } = pair
    const liquidityHydra = reserve0 * token0.derivedHYDRA + reserve1 * token1.derivedHYDRA
    const price = liquidityHydra / pair.totalSupply
    return price
  }

  return undefined
}

export async function getHydraInfo() {
  try {
    const hydraInfo = await fetch(HYDRA_INFO_API_URL).then((res) => res.json())
    return { data: hydraInfo, error: false, message: '' }
  } catch (error) {
    //@ts-ignore
    console.error('ERROR->[getHydraInfo]', error?.message)
    //@ts-ignore
    return { hydraApy: '', error: true, message: error && error?.message }
  }
}
