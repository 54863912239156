import { Currency } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { trimHexPrefix } from 'utils'

import HydraLogo from '../../assets/images/hydra-logo.png'

type Network = 'mainnet' | 'testnet'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'mainnet'
    case SupportedChainId.TESTNET:
      return 'testnet'
    default:
      return 'mainnet'
  }
}

export function getNativeLogoURI(): string {
  return HydraLogo
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.MAINNET): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [SupportedChainId.MAINNET]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Hydra-Chain/hydraswap-token-list/main/assets/blockchain/${networkName}/assets/${trimHexPrefix(
      address
    )?.toLowerCase()}/logo.png`
  }
}

export default function useCurrencyLogoURIs(currency?: Currency | null): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI())
      } else if (currency.isToken) {
        const logoURI = getTokenLogoURI(currency.address, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
