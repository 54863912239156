import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import { V2_DEX_GRAPH_ENDPOINT } from 'constants/index'
import { STAKING_LP_TOKENS, STAKING_TOKENS } from 'constants/staking'
import { formatUnits } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { useHydraChainId, useHydraHexAddress, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import { trimHexPrefix } from 'hydra/contracts/utils'
import { useStakingContract } from 'hydra/hooks/useContract'
import { Pair } from 'hydra-v2-sdk'
import JSBI from 'jsbi'
import { NEVER_RELOAD, useMultipleContractSingleData, useSingleCallResult } from 'lib/hooks/multicall'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import { DAI, UNI, USDC_MAINNET, USDT, WBTC, WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { compoundInterest, getHydraInfo, getPairPriceHydra } from './utils'

const STAKING_REWARDS_INTERFACE = new Interface(STAKING_REWARDS_ABI)

export const STAKING_GENESIS = 1600387200

export const REWARDS_DURATION_DAYS = 60

export const STAKING_REWARDS_INFO: {
  [chainId: number]: {
    tokens: [Token, Token]
    stakingRewardAddress: string
  }[]
} = {
  1: [
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token, DAI],
      stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711',
    },
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token, USDC_MAINNET],
      stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b',
    },
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token, USDT],
      stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a',
    },
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token, WBTC],
      stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e',
    },
  ],
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: CurrencyAmount<Token>
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: CurrencyAmount<Token>
  // the total amount of token staked in the contract
  totalStakedAmount: CurrencyAmount<Token>
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: CurrencyAmount<Token>
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: CurrencyAmount<Token>
  // when the period ends
  periodFinish: Date | undefined
  // if pool is active
  active: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: CurrencyAmount<Token>,
    totalStakedAmount: CurrencyAmount<Token>,
    totalRewardRate: CurrencyAmount<Token>
  ) => CurrencyAmount<Token>
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useWeb3React()

  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter((stakingRewardInfo) =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(
          CurrencyAmount.fromRawAmount(tokens[0], '0'),
          CurrencyAmount.fromRawAmount(tokens[1], '0')
        )

        // check for account, if no account set to 0

        const stakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(balanceState?.result?.[0] ?? 0)
        )
        const totalStakedAmount = CurrencyAmount.fromRawAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(totalSupplyState.result?.[0])
        )
        const totalRewardRate = CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: CurrencyAmount<Token>,
          totalStakedAmount: CurrencyAmount<Token>,
          totalRewardRate: CurrencyAmount<Token>
        ): CurrencyAmount<Token> => {
          return CurrencyAmount.fromRawAmount(
            uni,
            JSBI.greaterThan(totalStakedAmount.quotient, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.quotient, stakedAmount.quotient), totalStakedAmount.quotient)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate,
          stakedAmount,
          totalStakedAmount,
          getHypotheticalRewardRate,
          active,
        })
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni,
  ])
}

export function useStakingTokenConfig(address: string | undefined) {
  const trimmedAddress = trimHexPrefix(address)?.toLowerCase()
  return trimmedAddress
    ? STAKING_TOKENS[trimmedAddress as keyof typeof STAKING_TOKENS] ||
        STAKING_LP_TOKENS[trimmedAddress as keyof typeof STAKING_LP_TOKENS]
    : null
}

// get staking info for a token
export function useStakingInfoForToken(token: Token | undefined | null): StakingInfo | null {
  const [chainId] = useHydraChainId()
  const [account] = useHydraHexAddress()

  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const stakingConfig = useStakingTokenConfig(trimHexPrefix(token?.address)?.toLowerCase())
  const stakingToken = useToken(stakingConfig?.stakingToken.address)
  const rewardToken = useToken(stakingConfig?.rewardToken.address)

  const stakingAddress = stakingConfig?.stakingContract.address

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const stakingContract = useStakingContract(stakingAddress)
  const balance = useSingleCallResult(stakingContract, 'balances', accountArg)
  const earnedAmount = useSingleCallResult(stakingContract, 'earned', accountArg)
  const totalBalance = useSingleCallResult(stakingContract, 'totalBalance')

  // tokens per second, constants
  const rewardPerSecond = useSingleCallResult(stakingContract, 'rewardPerSecond', undefined, NEVER_RELOAD)
  const distributionFinish = useSingleCallResult(stakingContract, 'distributionFinish', undefined, NEVER_RELOAD)

  return useMemo(() => {
    if (!chainId || !rewardToken || !stakingToken || !account) {
      return null
    }

    // these two are dependent on account
    const balanceState = balance
    const earnedAmountState = earnedAmount

    // these get fetched regardless of account
    const totalSupplyState = totalBalance
    const rewardRateState = rewardPerSecond
    const periodFinishState = distributionFinish

    if (
      // these may be undefined if not logged in
      !balanceState?.loading &&
      !earnedAmountState?.loading &&
      // always need these
      totalSupplyState &&
      !totalSupplyState.loading &&
      rewardRateState &&
      !rewardRateState.loading &&
      periodFinishState &&
      !periodFinishState.loading
    ) {
      if (
        balanceState?.error ||
        earnedAmountState?.error ||
        totalSupplyState.error ||
        rewardRateState.error ||
        periodFinishState.error
      ) {
        console.error('Failed to load staking rewards info')
        return null
      }

      const stakedAmount = CurrencyAmount.fromRawAmount(stakingToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
      const totalStakedAmount = CurrencyAmount.fromRawAmount(stakingToken, JSBI.BigInt(totalSupplyState.result?.[0]))
      const totalRewardRate = CurrencyAmount.fromRawAmount(rewardToken, JSBI.BigInt(rewardRateState.result?.[0]))

      const getHypotheticalRewardRate = (
        stakedAmount: CurrencyAmount<Token>,
        totalStakedAmount: CurrencyAmount<Token>,
        totalRewardRate: CurrencyAmount<Token>
      ): CurrencyAmount<Token> => {
        // return annual reward
        return CurrencyAmount.fromRawAmount(
          rewardToken,
          JSBI.greaterThan(totalStakedAmount.quotient, JSBI.BigInt(0))
            ? JSBI.divide(
                JSBI.multiply(totalRewardRate.multiply(86400 * 365).quotient, stakedAmount.quotient),
                totalStakedAmount.add(stakedAmount).quotient
              )
            : JSBI.BigInt(0)
        )
      }

      const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

      const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
      const periodFinishMs = periodFinishSeconds * 1000

      // compare period end timestamp vs current block timestamp (in seconds)
      const active =
        periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

      return {
        stakingRewardAddress: stakingConfig?.stakingContract.address ?? '',
        tokens: [stakingToken, rewardToken],
        periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
        earnedAmount: CurrencyAmount.fromRawAmount(rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
        rewardRate: individualRewardRate,
        totalRewardRate,
        stakedAmount,
        totalStakedAmount,
        getHypotheticalRewardRate,
        active,
      }
    }
    return null
  }, [
    balance,
    chainId,
    currentBlockTimestamp,
    earnedAmount,
    distributionFinish,
    rewardPerSecond,
    stakingConfig,
    totalBalance,
    rewardToken,
    stakingToken,
    account,
  ])
}

export function useStakingAPY(amount: BigNumber, token: Token | undefined) {
  const stakingInfo = useStakingInfoForToken(token)
  const stakingConfig = useStakingTokenConfig(token?.address)

  const priceRatio = useStakingTokensPriceRatio(
    stakingConfig?.stakingToken.address,
    stakingConfig?.stakingToken.isPair,
    stakingConfig?.rewardToken.address
  )

  try {
    if (!stakingInfo) {
      return { apy: null, apyPercentage: null, aprPercentage: null }
    }

    // check if campaign is active
    if (!stakingInfo?.active) {
      return { apy: '0.00', apyPercentage: '0.00', aprPercentage: '0.00' }
    }

    if (stakingInfo && token && priceRatio) {
      const annualReward = stakingInfo.getHypotheticalRewardRate(
        CurrencyAmount.fromRawAmount(token, amount.toString()),
        stakingInfo.totalStakedAmount,
        stakingInfo.totalRewardRate
      )

      const amountNum = Number(formatUnits(amount, token.decimals)) / priceRatio
      const rewardNum = Number(formatUnits(annualReward.quotient.toString(), stakingInfo.rewardRate.currency.decimals))
      const apr = rewardNum / amountNum

      const apy = compoundInterest(amountNum, 1, apr, 31536000)
      const apyPercentage = (apy * 100) / amountNum
      return { apy: apy.toFixed(2), apyPercentage: apyPercentage.toFixed(2), aprPercentage: (apr * 100).toFixed(2) }
    }

    return { apy: '0.00', apyPercentage: '0.00', aprPercentage: '0.00' }
  } catch {
    return { apy: null, apyPercentage: null, aprPercentage: null }
  }
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token | undefined,
  userLiquidityUnstaked: CurrencyAmount<Token> | undefined
): {
  parsedAmount?: CurrencyAmount<Token>
  error?: ReactNode
} {
  const [account] = useHydraWalletAddress()

  const parsedInput: CurrencyAmount<Token> | undefined =
    tryParseCurrencyAmount(typedValue, stakingToken) || tryParseCurrencyAmount('0', stakingToken)

  const parsedAmount = parsedInput

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (!parsedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return {
    parsedAmount,
    error,
  }
}

interface GraphToken {
  id: string
  derivedHydra: string
}

export function useStakingTokensPriceRatio(
  stakingTknAddress: string | undefined,
  stakingTknIsPair: boolean | undefined,
  rewardTknAddress: string | undefined
) {
  const [priceRatio, setPriceRatio] = useState(stakingTknAddress === rewardTknAddress ? 1 : null)
  const stakingCurrency = useToken(stakingTknAddress)
  const rewardCurrency = useToken(rewardTknAddress)

  useEffect(() => {
    if (stakingCurrency && rewardCurrency && stakingCurrency.address !== rewardCurrency.address) {
      const ids = [stakingTknAddress, rewardTknAddress]
      // TODO this should be optimized
      fetch(V2_DEX_GRAPH_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `{
            tokens (where: { id_in: ${JSON.stringify(ids)} }) {
              id
              derivedHYDRA
            }
          }`,
        }),
      })
        .then((res) => res.json())
        .then(async (res) => {
          if (res.errors) {
            throw res.errors[0].message
          }

          if (res.data?.tokens?.length) {
            const { tokens } = res.data
            const tokenAPrice = !stakingTknIsPair
              ? Number(tokens.find((tkn: GraphToken) => tkn.id === stakingTknAddress)?.derivedHYDRA)
              : await getPairPriceHydra(stakingTknAddress)
            const tokenBPrice = Number(tokens.find((tkn: GraphToken) => tkn.id === rewardTknAddress)?.derivedHYDRA)

            const ratio = tokenAPrice && tokenBPrice ? tokenBPrice / tokenAPrice : 1
            setPriceRatio(ratio)
          }
        })
        .catch(console.log)
    }
  }, [stakingCurrency, rewardCurrency, rewardTknAddress, stakingTknAddress, stakingTknIsPair])

  return priceRatio
}

export function useHydraStakingInfo() {
  const [apy, setApy] = useState('')

  useEffect(() => {
    const calculateAPY = async () => {
      try {
        const { data: hydraInfo } = await getHydraInfo()
        const netStakedWeight = ((hydraInfo?.circulatingSupply || 0) - 560000) * 0.94
        const apy = (((parseFloat(hydraInfo?.supply || 0) * 0.02) / netStakedWeight) * 100).toFixed(2) || `0.00`
        setApy(apy)
      } catch (error) {
        console.error(error)
      }
    }

    calculateAPY()
  }, [])

  return {
    apy,
  }
}
