import { Currency, CurrencyAmount, Price, Token, TradeType } from '@uniswap/sdk-core'
import { ChainId, WHYDRA } from 'hydra/sdk'
import { useMemo, useRef } from 'react'

import { useClientSideV3Trade } from './useClientSideV3Trade'

// Whydra amounts used when calculating spot price for a given currency.
export const WHYDRA_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token> } = {
  [ChainId.MAINNET]: CurrencyAmount.fromRawAmount(WHYDRA[1], 10e8),
  [ChainId.TESTNET]: CurrencyAmount.fromRawAmount(WHYDRA[2], 10e8),
}

/**
 * Returns the price in WHYDRA of the input currency
 * @param currency currency to compute the WHYDRA price of
 */
export default function useWhydraPrice(currency?: Currency): Price<Currency, Token> | undefined {
  const chainId = currency?.chainId

  const amountOut = chainId ? WHYDRA_AMOUNT_OUT[chainId] : undefined
  const whydra = amountOut?.currency
  const bestV3Trade = useClientSideV3Trade(TradeType.EXACT_OUTPUT, amountOut, currency)?.trade

  const price = useMemo(() => {
    if (!currency || !whydra) {
      return undefined
    }

    // handle whydra
    if (currency?.wrapped.equals(whydra)) {
      return new Price(whydra, whydra, '1', '1')
    }

    if (bestV3Trade) {
      const { numerator, denominator } = bestV3Trade.routes[0].midPrice
      return new Price(currency, whydra, denominator, numerator)
    }

    return undefined
  }, [currency, whydra, bestV3Trade])

  const lastPrice = useRef(price)
  if (!price || !lastPrice.current || !price.equalTo(lastPrice.current)) {
    lastPrice.current = price
  }
  return lastPrice.current
}

export function useWhydraValue(currencyAmount: CurrencyAmount<Currency> | undefined | null) {
  const price = useWhydraPrice(currencyAmount?.currency)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}
