import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'
import { ButtonLight, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { EXPLORER_URL } from 'constants/index'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { useHydraHexAddress, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { contractSend } from 'hydra/contracts/utils'
import { useDAOFundsContract } from 'hydra/hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import AppBody from 'pages/AppBody'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useConnectHydra, useToggleConnectModal } from 'state/application/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 540px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
    padding: 0px 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const StyledDAOHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.deprecated_text2};
`
const BoldSpan = styled.span`
  font-weight: 600;
`

function DAOHeader() {
  return (
    <StyledDAOHeader>
      <ThemedText.DeprecatedBlack fontWeight={500} textAlign={'center'} fontSize={16} style={{ marginRight: '8px' }}>
        DAO Funds Contract
      </ThemedText.DeprecatedBlack>
    </StyledDAOHeader>
  )
}

export default function DAO() {
  const [poolReserve, setPoolReserve] = useState<string | null>(null)
  const addTransaction = useTransactionAdder()
  const { transactionData, setTransactionData } = useContext(TransactionConfirmationContext)

  const toggleConnectModal = useToggleConnectModal()
  const connectHydra = useConnectHydra()

  const connectWallet = useCallback(() => {
    toggleConnectModal()
    connectHydra()
  }, [toggleConnectModal, connectHydra])

  const [hexAddr] = useHydraHexAddress()
  const [account] = useHydraWalletAddress()
  const daoFundsContract = useDAOFundsContract()
  const fiatRate = useSingleCallResult(daoFundsContract, 'ethFiatRate')?.result?.[0]
  const recipient = useSingleCallResult(daoFundsContract, 'recipients', [hexAddr])?.result

  const monthlyBudget: BigNumber | null = useMemo(() => {
    if (recipient?.dailyAmount) {
      return recipient.dailyAmount.mul(365).div(12)
    }
    return null
  }, [recipient])

  const [currentBalanceFiat, currentBalanceHydra] = useMemo(() => {
    if (recipient?.dailyAmount && recipient?.lastClaimed && fiatRate) {
      const elapsedTime = BigNumber.from(new Date().getTime()).sub(recipient.lastClaimed.mul(1000)).div(1000)
      const currentBalanceFiat = recipient.dailyAmount.mul(elapsedTime).div(86400)
      const currentBalanceHydra = currentBalanceFiat.mul(1e5).div(fiatRate)

      return [currentBalanceFiat.toString(), currentBalanceHydra.toString()]
    }

    return [null, null]
  }, [recipient, fiatRate])

  useEffect(() => {
    if (daoFundsContract?.address) {
      fetch(`${EXPLORER_URL}/api/contract/${daoFundsContract?.address}`)
        .then((res) => res.json())
        .then((res) => {
          setPoolReserve(formatUnits(res.balance, 8))
        })
        .catch(console.log)
    }
  }, [daoFundsContract])

  const handleWithdraw = useCallback(() => {
    if (daoFundsContract) {
      setTransactionData({
        ...transactionData,
        showConfirm: true,
        attempting: true,
        pendingText: `Withdrawing...`,
        hash: '',
        txError: '',
      })
      contractSend(daoFundsContract, 'withdrawFunds', [], account)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.SEND_TRANSACTION,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
        })
    }
  }, [daoFundsContract, addTransaction, setTransactionData, transactionData, account])

  return (
    <AppBody>
      <PageWrapper>
        <DAOHeader />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%', padding: '1.5rem' }}>
            <Text>
              <BoldSpan style={{ fontWeight: '600' }}>Fiat rate: </BoldSpan>${fiatRate ? formatUnits(fiatRate, 5) : '-'}
            </Text>
            <Text>
              <BoldSpan>Pool reserve: </BoldSpan>
              {poolReserve} HYDRA
            </Text>
            <Text>
              <BoldSpan>Current balance in Fiat: </BoldSpan>
              {currentBalanceFiat ? `$${currentBalanceFiat}` : '-'}
            </Text>
            <Text>
              <BoldSpan>Current balance in HYDRA: </BoldSpan>
              {currentBalanceHydra ? `${currentBalanceHydra} HYDRA` : '-'}
            </Text>
            <Text>
              <BoldSpan>Monthly budget: </BoldSpan>${monthlyBudget ? monthlyBudget?.toString() : '-'}
            </Text>

            {hexAddr ? (
              <ButtonPrimary onClick={handleWithdraw}>Withdraw</ButtonPrimary>
            ) : (
              <ButtonLight onClick={connectWallet} $borderRadius="12px" padding={'12px'}>
                Connect Wallet
              </ButtonLight>
            )}
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </AppBody>
  )
}
