import { BigNumber } from '@ethersproject/bignumber'
import { PageName } from 'components/AmplitudeAnalytics/constants'
import { ElementName, Event, EventName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { TraceEvent } from 'components/AmplitudeAnalytics/TraceEvent'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Row from 'components/Row'
import StakePositionList from 'components/StakePositionList'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { formatUnits } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { useHydraHexAddress, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import { contractSend } from 'hydra/contracts/utils'
import { useV3StakerContract } from 'hydra/hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCallback, useContext } from 'react'
import { Inbox } from 'react-feather'
import { useParams } from 'react-router-dom'
import { useConnectHydra, useToggleConnectModal } from 'state/application/hooks'
import { useIncentiveConfig, useIncentivePositions } from 'state/incentive/hooks'
import { formatIncentiveTimestamp } from 'state/incentive/utils'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { css, useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { LoadingRows } from './styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
    padding: 0px 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const TitleRow = styled(Row)`
  justify-content: center;
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.deprecated_bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

export default function StakeV3() {
  const { incentiveId } = useParams()
  const incentiveConfig = useIncentiveConfig(incentiveId ?? '')
  const rewardToken = useToken(incentiveConfig?.key.rewardToken)

  const [account] = useHydraWalletAddress()
  const [hexAddr] = useHydraHexAddress()

  const toggleConnectModal = useToggleConnectModal()
  const connectHydra = useConnectHydra()

  const connectWallet = useCallback(() => {
    toggleConnectModal()
    connectHydra()
  }, [toggleConnectModal, connectHydra])

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const { transactionData, setTransactionData } = useContext(TransactionConfirmationContext)

  const v3StakerContract = useV3StakerContract()
  const earnedReward: BigNumber | undefined = useSingleCallResult(v3StakerContract, 'rewards', [
    rewardToken?.address,
    hexAddr,
  ])?.result?.[0]

  const { positions, loading: positionsLoading } = useIncentivePositions(incentiveId)

  const theme = useTheme()

  const handleClaimReward = useCallback(() => {
    if (earnedReward?.gt(0)) {
      if (!v3StakerContract || !rewardToken || !account) {
        return
      }

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Claiming  ${formatUnits(earnedReward, rewardToken.decimals)} ${rewardToken.symbol}`,
        hash: '',
        txError: '',
      })
      contractSend(v3StakerContract, 'claimReward', [rewardToken?.address, account, 0], account)
        .then((tx) => {
          tx.hash = tx.id
          addTransaction(tx, {
            type: TransactionType.WITHDRAW_STAKING_REWARD,
            tokenAddress: rewardToken?.address,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [v3StakerContract, rewardToken, earnedReward, account, transactionData, setTransactionData, addTransaction])

  return (
    <Trace page={PageName.STAKE_V3_PAGE} shouldLogImpression>
      <>
        <PageWrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <AutoColumn gap="sm">
                <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                  <ThemedText.LargeHeader textAlign={'center'}>Stake {incentiveConfig?.name}</ThemedText.LargeHeader>
                </TitleRow>

                <TitleRow>
                  <ThemedText.BodySecondary fontWeight={500} textAlign={'center'}>
                    ({formatIncentiveTimestamp(incentiveConfig?.key.startTime)} -:-{' '}
                    {formatIncentiveTimestamp(incentiveConfig?.key.endTime)})
                  </ThemedText.BodySecondary>
                </TitleRow>
              </AutoColumn>

              {account && earnedReward && (
                <TitleRow justifyContent={'center'}>
                  <ThemedText.BodyPrimary fontWeight={600} marginRight={'16px'}>
                    Claimable rewards: {formatUnits(earnedReward, rewardToken?.decimals)} {rewardToken?.symbol}
                  </ThemedText.BodyPrimary>
                  {earnedReward.gt(0) && (
                    <ButtonPrimary maxWidth={'90px'} fontSize={'16px'} padding={'2px 0'} onClick={handleClaimReward}>
                      Claim
                    </ButtonPrimary>
                  )}
                </TitleRow>
              )}

              <MainContentWrapper>
                {positionsLoading ? (
                  <PositionsLoadingPlaceholder />
                ) : positions && positions.length > 0 ? (
                  <StakePositionList positions={positions} incentiveId={incentiveId ?? ''} />
                ) : (
                  <ErrorContainer>
                    <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                      <InboxIcon strokeWidth={1} />
                      <div>Your V3 liquidity positions for this pool will appear here.</div>
                    </ThemedText.DeprecatedBody>
                    {!account && (
                      <TraceEvent
                        events={[Event.onClick]}
                        name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                        properties={{ received_swap_quote: false }}
                        element={ElementName.CONNECT_WALLET_BUTTON}
                      >
                        <ButtonPrimary style={{ marginTop: '2em', padding: '8px 16px' }} onClick={connectWallet}>
                          Connect a wallet
                        </ButtonPrimary>
                      </TraceEvent>
                    )}
                  </ErrorContainer>
                )}
              </MainContentWrapper>
            </AutoColumn>
          </AutoColumn>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    </Trace>
  )
}
