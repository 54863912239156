import { Token } from '@uniswap/sdk-core'
import { ChainId, WHYDRA } from 'hydra/sdk'

// Hydra tokens
export const LOC = new Token(ChainId.MAINNET, '0x4ab26aaa1803daa638910d71075c06386e391147', 8, 'LOC', 'LockTrip')
export const BUSD = new Token(
  ChainId.MAINNET,
  '0x0c404645b9e36b6cf9bcaf2747e472334b268e9d',
  18,
  'BUSD',
  'HYDRA-Pegged BUSD'
)
export const USDT = new Token(
  ChainId.MAINNET,
  '0x932917c89717c809f67ebb421e24fd04d7a96d33',
  6,
  'USDT',
  'HYDRA-Pegged USDT'
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0xe4b8164dccf3489f66124cace1570dc35b58fc90',
  6,
  'USDC',
  'HYDRA-Pegged USDC'
)
export const DAI = new Token(
  ChainId.MAINNET,
  '0xabc2cd00700e06922bcf30fe0ad648507113cc56',
  18,
  'DAI',
  'HYDRA-Pegged DAI'
)
export const WBTC = new Token(
  ChainId.MAINNET,
  '0x27a459ac47204f1e7fc5c48ffeafa990af244e13',
  8,
  'WBTC',
  'HYDRA-Pegged Bitcoin'
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0xab9e970de9ba9ec2c64a7f7f911da44b750ecf6a',
  18,
  'ETH',
  'HYDRA-Pegged Ethereum'
)

export const USDC_TESTNET = new Token(ChainId.TESTNET, '0xe8abdbb2d279429b6b0381abf97827892b2c5f52', 6, 'USDC', 'USDC')
export const WBTC_TESTNET = new Token(ChainId.TESTNET, '0x15a7fb6d1bb3a91dc55ec7eb45c5b41c63bc5f3a', 8, 'WBTC', 'WBTC')
export const ETH_TESTNET = new Token(
  ChainId.TESTNET,
  '0x950c3f81fa44f700cb59ba56abb184c879d6a0cc',
  18,
  'ETH',
  'Ethereum'
)
export const DAI_TESTNET = new Token(
  ChainId.TESTNET,
  '0x2f859738695d1699844ec197359f55c306c4eb74',
  18,
  'Dai Stablecoin',
  'DAI'
)
export const LYDRA_TESTNET = new Token(
  ChainId.TESTNET,
  '0x438d420c1cadefab544c5d2b9adf62c380b40934',
  8,
  'LYDRA',
  'Liquid HYDRA'
)
export const LYDRA_MAINNET = new Token(
  ChainId.MAINNET,
  '0x68a674e10694eeb00867d2e11c8b158b00b28e96',
  8,
  'LYDRA',
  'Liquid HYDRA'
)
export const LYDRA: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: LYDRA_MAINNET,
  [ChainId.TESTNET]: LYDRA_TESTNET,
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WHYDRA[ChainId.MAINNET]],
  [ChainId.TESTNET]: [WHYDRA[ChainId.TESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, USDC, WBTC, ETH, LYDRA_MAINNET],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */

export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainTokenList = {
  ...WETH_ONLY,
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, USDC, WBTC, ETH, LYDRA_MAINNET],
}

export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.MAINNET]: [],
}
