import { BaseVariant } from 'featureFlags'
import { useRedesignFlag } from 'featureFlags/flags/redesign'
import { useHydraChainId } from 'hooks/useAddHydraAccExtension'
import { useEffect } from 'react'
import { useDarkModeManager } from 'state/user/hooks'

const initialStyles = {
  width: '200vw',
  height: '200vh',
  transform: 'translate(-50vw, -100vh)',
  backgroundBlendMode: '',
}
const backgroundResetStyles = {
  width: '100vw',
  height: '100vh',
  transform: 'unset',
  backgroundBlendMode: '',
}

type TargetBackgroundStyles = typeof initialStyles | typeof backgroundResetStyles

const backgroundRadialGradientElement = document.getElementById('background-radial-gradient')
const setBackground = (newValues: TargetBackgroundStyles) =>
  Object.entries(newValues).forEach(([key, value]) => {
    if (backgroundRadialGradientElement) {
      backgroundRadialGradientElement.style[key as keyof typeof backgroundResetStyles] = value
    }
  })
export default function RadialGradientByChainUpdater(): null {
  const [chainId] = useHydraChainId()
  const [darkMode] = useDarkModeManager()
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === BaseVariant.Enabled
  // manage background color
  useEffect(() => {
    if (!backgroundRadialGradientElement) {
      return
    }

    setBackground(initialStyles)
    const defaultLightGradient = redesignFlagEnabled
      ? 'radial-gradient(100% 100% at 50% 0%, rgba(255, 184, 226, 0.51) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF'
      : 'radial-gradient(50% 50% at 50% 50%, rgba(33, 114, 229, 0.1) 0%, rgba(33, 36, 41, 0) 100%)'
    const defaultDarkGradient = redesignFlagEnabled
      ? 'linear-gradient(180deg, #202738 0%, #070816 100%)'
      : 'radial-gradient(50% 50% at 50% 50%, rgba(33, 114, 229, 0.1) 0%, rgba(33, 36, 41, 0) 100%)'
    backgroundRadialGradientElement.style.background = darkMode ? defaultDarkGradient : defaultLightGradient
    backgroundRadialGradientElement.style.backgroundBlendMode = redesignFlagEnabled
      ? 'none'
      : darkMode
      ? 'overlay,normal'
      : 'multiply,normal'
  }, [darkMode, chainId, redesignFlagEnabled])
  return null
}
