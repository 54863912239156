interface SortFnProps {
  data: any
  ascending: boolean
  type: string
  sortKey: string
}

export const SORT_KEY_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
}

export const getSortedData = ({ data, ascending, type, sortKey }: SortFnProps) => {
  let sortedData
  if (type === SORT_KEY_TYPE.STRING && ascending) {
    sortedData = [...data].sort((a, b) => (a[sortKey].toLowerCase() < b[sortKey].toLowerCase() ? -1 : 1))
  } else if (type === SORT_KEY_TYPE.STRING && !ascending) {
    sortedData = [...data].sort((a, b) => (a[sortKey].toLowerCase() > b[sortKey].toLowerCase() ? -1 : 1))
  } else if (type === SORT_KEY_TYPE.NUMBER && ascending) {
    sortedData = [...data].sort((a, b) => (a[sortKey] < b[sortKey] ? -1 : 1))
  } else if (type === SORT_KEY_TYPE.NUMBER && !ascending) {
    sortedData = [...data].sort((a, b) => (a[sortKey] > b[sortKey] ? -1 : 1))
  }
  return { data: sortedData || [], ascending: !ascending }
}
