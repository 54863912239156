import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Price, Token } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import RangeBadge from 'components/Badge/RangeBadge'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import HoverInlineText from 'components/HoverInlineText'
import Loader from 'components/Loader'
import Row, { RowEnd } from 'components/Row'
import { V3_STAKER_ADDRESSES } from 'constants/addresses'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { formatUnits, Interface } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { useHydraChainId, useHydraHexAddress, useHydraWalletAddress } from 'hooks/useAddHydraAccExtension'
import useIsTickAtLimit from 'hooks/useIsTickAtLimit'
import { usePool } from 'hooks/usePools'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import { rawSend } from 'hydra/contracts/rawFunctions'
import { contractSend, hydraToHexAddress } from 'hydra/contracts/utils'
import { useV3NFTPositionManagerContract, useV3StakerContract } from 'hydra/hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCallback, useContext, useMemo } from 'react'
import { PositionStakingState, useIncentiveConfig, usePositionStakingState } from 'state/incentive/hooks'
import { getIncentiveKeyEncoded } from 'state/incentive/utils'
import { Bound } from 'state/mint/v3/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { HideSmall, MEDIA_WIDTHS, SmallOnly } from 'theme'
import { StakingPositionDetails } from 'types/position'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

import { DAI, USDC_MAINNET, USDT, WBTC, WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'

const PositionRow = styled(Row)`
  align-items: center;
  border-radius: 20px;
  display: flex;
  user-select: none;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.deprecated_text1};
  margin: 8px 0;
  padding: 16px;
  text-decoration: none;
  font-weight: 500;
  background-color: ${({ theme }) => theme.deprecated_bg1};

  &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  }
  :hover {
    background-color: ${({ theme }) => theme.deprecated_bg2};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`

const PositionInfoRow = styled(Row)`
  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    flex-direction: column;
  }
`

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`

const DataLineItem = styled.div`
  font-size: 14px;
`

const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 4px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  background-color: ${({ theme }) => theme.deprecated_bg2};
    border-radius: 12px;
    padding: 8px 0;
`};
`

const DoubleArrow = styled.span`
  margin: 0 2px;
  color: ${({ theme }) => theme.deprecated_text3};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 4px;
    padding: 20px;
  `};
`

const RangeText = styled.span`
  /* background-color: ${({ theme }) => theme.deprecated_bg2}; */
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
`

const ExtentsText = styled.span`
  color: ${({ theme }) => theme.deprecated_text3};
  font-size: 14px;
  margin-right: 4px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`

const DataText = styled.div`
  font-weight: 600;
  font-size: 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const ActionButton = styled(ButtonPrimary)`
  max-width: 120px;
  padding: 8px 0px;
`

interface PositionListItemProps {
  positionDetails: StakingPositionDetails
  incentiveId: string
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC_MAINNET, USDT]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY), WBTC]
  if (bases.some((base) => base && base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export default function StakePositionListItem({ positionDetails, incentiveId }: PositionListItemProps) {
  const {
    tokenId,
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    stakes: { liquidity: stakingLiquidity },
    deposits: { owner, numberOfStakes },
  } = positionDetails

  const [chainId] = useHydraChainId()
  const [account] = useHydraWalletAddress()
  const [hexAddr] = useHydraHexAddress()

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const { transactionData, setTransactionData } = useContext(TransactionConfirmationContext)

  const stakingState = usePositionStakingState({
    stakingLiquidity,
    owner,
    numberOfStakes,
  })
  const incentiveConfig = useIncentiveConfig(incentiveId)

  const rewardToken = useToken(incentiveConfig?.key.rewardToken)
  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  const positionManagerContract = useV3NFTPositionManagerContract()
  const v3StakerContract = useV3StakerContract()

  const earned: BigNumber | undefined = useSingleCallResult(v3StakerContract, 'getRewardInfo', [
    Object.values(incentiveConfig?.key ?? {}),
    tokenId,
  ])?.result?.reward

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

  const position = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  // prices
  const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  // check if price is within range
  const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  const removed = liquidity?.eq(0)

  const price0 = useStablecoinPrice(token0 ?? undefined)
  const price1 = useStablecoinPrice(token1 ?? undefined)
  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const handleDeposit = useCallback(() => {
    if (stakingState === PositionStakingState.NOT_DEPOSITED) {
      if (!positionManagerContract || !hexAddr || !chainId || !incentiveConfig || !tokenId) {
        return
      }

      const incentiveKeyEncoded = getIncentiveKeyEncoded(incentiveConfig.key)

      const iface = new Interface(positionManagerContract.abi)

      // safeTransferFrom
      const hex = iface.encodeFunctionData('0xb88d4fde', [
        hexAddr,
        V3_STAKER_ADDRESSES[chainId],
        tokenId,
        incentiveKeyEncoded,
      ])

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Staking position ${tokenId}`,
        hash: '',
        txError: '',
      })

      rawSend(positionManagerContract, positionManagerContract.address, hex, 0, account, 300000)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.STAKE_POSITION,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [
    stakingState,
    positionManagerContract,
    chainId,
    incentiveConfig,
    hexAddr,
    tokenId,
    account,
    setTransactionData,
    transactionData,
    addTransaction,
  ])

  const handleStake = useCallback(() => {
    if (stakingState === PositionStakingState.DEPOSITED || stakingState === PositionStakingState.STAKED_IN_OTHER) {
      if (!v3StakerContract || !incentiveConfig || !tokenId || !account) {
        return
      }

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Staking position ${tokenId}`,
        hash: '',
        txError: '',
      })
      contractSend(v3StakerContract, 'stakeToken', [incentiveConfig.key, tokenId], account)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.STAKE_POSITION,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [
    stakingState,
    v3StakerContract,
    incentiveConfig,
    tokenId,
    account,
    transactionData,
    setTransactionData,
    addTransaction,
  ])

  const handleUnstake = useCallback(() => {
    if (stakingState === PositionStakingState.STAKED) {
      if (!v3StakerContract || !incentiveConfig || !tokenId || !account) {
        return
      }

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Unstaking position ${tokenId}`,
        hash: '',
        txError: '',
      })
      contractSend(v3StakerContract, 'unstakeToken', [incentiveConfig.key, tokenId], account)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.UNSTAKE_POSITION,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [
    stakingState,
    v3StakerContract,
    incentiveConfig,
    tokenId,
    account,
    transactionData,
    setTransactionData,
    addTransaction,
  ])

  const handleWithdraw = useCallback(() => {
    if (stakingState === PositionStakingState.DEPOSITED) {
      if (!v3StakerContract || !hexAddr || !tokenId) {
        return
      }

      const iface = new Interface(v3StakerContract.abi)

      // safeTransferFrom
      const hex = iface.encodeFunctionData('withdrawToken', [tokenId, hexAddr, []])

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Withdrawing position ${tokenId}`,
        hash: '',
        txError: '',
      })

      rawSend(v3StakerContract, v3StakerContract.address, hex, 0, account)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.WITHDRAW_POSITION,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [stakingState, v3StakerContract, hexAddr, tokenId, account, setTransactionData, transactionData, addTransaction])

  const handleClaimPositionRewards = useCallback(() => {
    if (stakingState === PositionStakingState.STAKED && earned?.gt(0)) {
      if (!v3StakerContract || !incentiveConfig || !tokenId || !account) {
        return
      }

      const iface = new Interface(v3StakerContract.abi)

      const unstakeHex = iface.encodeFunctionData('unstakeToken', [incentiveConfig.key, tokenId])
      const claimHex = iface.encodeFunctionData('claimReward', [
        incentiveConfig?.key.rewardToken,
        hydraToHexAddress(account),
        0,
      ])
      const stakeHex = iface.encodeFunctionData('stakeToken', [incentiveConfig.key, tokenId])

      const timestamp = new Date().getTime() / 1000
      const data =
        timestamp >= Number(incentiveConfig.key.endTime) ? [unstakeHex, claimHex] : [unstakeHex, claimHex, stakeHex]

      setTransactionData({
        showConfirm: true,
        attempting: true,
        pendingText: `Claiming  ${formatUnits(earned, rewardToken?.decimals)} ${rewardToken?.symbol}`,
        hash: '',
        txError: '',
      })

      contractSend(v3StakerContract, 'multicall', [data], account)
        .then((tx) => {
          tx.hash = tx.id
          if (!tx.hash) {
            throw new Error(tx.message)
          }

          addTransaction(tx, {
            type: TransactionType.WITHDRAW_STAKING_REWARD,
            tokenAddress: incentiveConfig.key.rewardToken,
          })

          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            hash: tx.hash,
          })
        })
        .catch((err) => {
          setTransactionData({
            ...transactionData,
            showConfirm: true,
            attempting: false,
            txError: err?.message ?? '',
          })
          console.error(err)
        })
    }
  }, [
    earned,
    stakingState,
    v3StakerContract,
    incentiveConfig,
    tokenId,
    account,
    addTransaction,
    setTransactionData,
    transactionData,
    rewardToken,
  ])

  const currentTimestamp = new Date().getTime() / 1000
  const isStakable = useMemo(
    () =>
      currentTimestamp >= Number(incentiveConfig?.key.startTime) &&
      currentTimestamp < Number(incentiveConfig?.key.endTime),
    [currentTimestamp, incentiveConfig]
  )

  return (
    <PositionRow>
      <AutoColumn>
        <PositionInfoRow>
          <PrimaryPositionIdData>
            <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={18} margin />
            <DataText>
              &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
            </DataText>
            &nbsp;
            <Badge>
              <BadgeText>
                <Trans>{new Percent(feeAmount, 1_000_000).toSignificant()}%</Trans>
              </BadgeText>
            </Badge>
            <Badge>
              <BadgeText>
                <Trans>${fiatValueOfLiquidity?.toFixed(2, { groupSeparator: ',' })}</Trans>
              </BadgeText>
            </Badge>
          </PrimaryPositionIdData>
          <RangeBadge removed={removed} inRange={!outOfRange} />
        </PositionInfoRow>

        {priceLower && priceUpper ? (
          <RangeLineItem>
            <RangeText>
              <ExtentsText>
                <Trans>Min: </Trans>
              </ExtentsText>
              <Trans>
                {formatTickPrice(priceLower, tickAtLimit, Bound.LOWER)} <HoverInlineText text={currencyQuote?.symbol} />{' '}
                per <HoverInlineText text={currencyBase?.symbol ?? ''} />
              </Trans>
            </RangeText>{' '}
            <HideSmall>
              <DoubleArrow>⟷</DoubleArrow>{' '}
            </HideSmall>
            <SmallOnly>
              <DoubleArrow>⟷</DoubleArrow>{' '}
            </SmallOnly>
            <RangeText>
              <ExtentsText>
                <Trans>Max:</Trans>
              </ExtentsText>
              <Trans>
                {formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER)} <HoverInlineText text={currencyQuote?.symbol} />{' '}
                per <HoverInlineText maxCharacters={10} text={currencyBase?.symbol} />
              </Trans>
            </RangeText>
          </RangeLineItem>
        ) : (
          <Loader />
        )}

        {earned && (
          <RangeLineItem style={{ margin: '0' }}>
            <RangeText>
              <ExtentsText style={{ display: 'inline' }}>
                <Trans>Earned: </Trans>
              </ExtentsText>
              <Trans>
                {formatUnits(earned, rewardToken?.decimals)} {rewardToken?.symbol}
              </Trans>
            </RangeText>
          </RangeLineItem>
        )}
      </AutoColumn>

      {stakingState === PositionStakingState.STAKED ? (
        <RowEnd maxWidth={'250px'} style={{ gap: '10px' }}>
          {earned?.gt(0) && <ActionButton onClick={handleClaimPositionRewards}>Claim</ActionButton>}
          <ButtonSecondary maxWidth={'120px'} padding={'8px 0px'} onClick={handleUnstake}>
            Unstake
          </ButtonSecondary>
        </RowEnd>
      ) : stakingState === PositionStakingState.DEPOSITED ? (
        <RowEnd maxWidth={'250px'} style={{ gap: '10px' }}>
          {isStakable && <ActionButton onClick={handleStake}>Stake</ActionButton>}
          <ButtonSecondary maxWidth={'120px'} padding={'8px 0px'} onClick={handleWithdraw}>
            Withdraw
          </ButtonSecondary>
        </RowEnd>
      ) : stakingState === PositionStakingState.STAKED_IN_OTHER ? (
        isStakable && <ActionButton onClick={handleStake}>Stake</ActionButton>
      ) : (
        isStakable && <ActionButton onClick={handleDeposit}>Stake</ActionButton>
      )}
    </PositionRow>
  )
}
