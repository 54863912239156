import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowFixed } from '../Row'

const StyledLydraHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.deprecated_text2};
`

export default function LydraHeader() {
  return (
    <StyledLydraHeader>
      <RowFixed>
        <ThemedText.DeprecatedBlack fontWeight={500} fontSize={16} style={{ marginRight: '8px' }}>
          <Trans>Mint/Burn LYDRA</Trans>
        </ThemedText.DeprecatedBlack>
      </RowFixed>
    </StyledLydraHeader>
  )
}
