import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { TransactionConfirmationContext } from 'contexts/TransactionConfirmationContext'
import { useHydraAccount, useHydraChainId, useHydraHexAddress, useHydraLibrary } from 'hooks/useAddHydraAccExtension'
import { useTokenAllowance } from 'hooks/useTokenAllowance'
import { AbiToken } from 'hydra/contracts/abi'
import { approve as approveToken } from 'hydra/contracts/tokenFunctions'
import { contractSend, getContract } from 'hydra/contracts/utils'
import { useV3NFTPositionManagerContract } from 'hydra/hooks/useContract'
import { useCallback, useContext, useMemo } from 'react'
import { areEqualAddresses } from 'utils'

import { useSingleCallResult } from './multicall'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

function useApprovalStateForSpender(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): ApprovalState {
  const [hexAddr] = useHydraHexAddress()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  const currentAllowance = useTokenAllowance(token, hexAddr ?? undefined, spender)
  const pendingApproval = useIsPendingApproval(token, spender)

  return useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])
}

export function useApproval(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): [
  ApprovalState,
  () => Promise<{ response: TransactionResponse; tokenAddress: string; spenderAddress: string } | undefined>
] {
  const [chainId] = useHydraChainId()
  const [account] = useHydraAccount()
  const [library] = useHydraLibrary()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  // check the current approval status
  const approvalState = useApprovalStateForSpender(amountToApprove, spender, useIsPendingApproval)

  // const tokenContract = useTokenContract(token?.address)
  const tokenContract = getContract(library, token?.address.toLowerCase(), AbiToken)

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`${token?.symbol || 'Token'} approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!token) {
      return logFailure('no token')
    } else if (!tokenContract) {
      return logFailure('tokenContract is null')
    } else if (!amountToApprove) {
      return logFailure('missing amount to approve')
    } else if (!spender) {
      return logFailure('no spender')
    }

    return approveToken(spender, tokenContract, account, MaxUint256)
      .then((response) => {
        response.hash = response.txid
        return {
          response,
          tokenAddress: token.address,
          spenderAddress: spender,
        }
      })
      .catch((error) => {
        logFailure(error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, chainId, account])

  return [approvalState, approve]
}

function useApprovalStateForPosition(
  tokenId: string | undefined,
  operator: string | undefined,
  useIsPendingApproval: (tokenId?: string, operator?: string) => boolean
): ApprovalState {
  const positionManagerContract = useV3NFTPositionManagerContract()
  const currentOperator = useSingleCallResult(positionManagerContract, 'getApproved', [tokenId])?.result?.[0]
  const pendingApproval = useIsPendingApproval(tokenId, operator)

  return useMemo(() => {
    if (!tokenId || !operator) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (!currentOperator) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return !areEqualAddresses(operator, currentOperator)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [currentOperator, tokenId, pendingApproval, operator])
}

export function usePositionApproval(
  tokenId: string | undefined,
  operator: string | undefined,
  useIsPendingApproval: (tokenId?: string, operator?: string) => boolean
): [ApprovalState, () => Promise<{ response: TransactionResponse; tokenId: string; operator: string } | undefined>] {
  const [chainId] = useHydraChainId()
  const [account] = useHydraAccount()

  const { transactionData, setTransactionData } = useContext(TransactionConfirmationContext)

  // check the current approval status
  const approvalState = useApprovalStateForPosition(tokenId, operator, useIsPendingApproval)

  const positionManagerContract = useV3NFTPositionManagerContract()

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`Position ${tokenId} approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!tokenId) {
      return logFailure('no tokenId')
    } else if (!positionManagerContract) {
      return logFailure('positionManagerContract is null')
    } else if (!operator) {
      return logFailure('no operator')
    }

    setTransactionData({
      showConfirm: true,
      attempting: true,
      pendingText: `Approving position ${tokenId}`,
      hash: '',
      txError: '',
    })

    return contractSend(positionManagerContract, 'approve', [operator, tokenId], account)
      .then((response) => {
        response.hash = response.txid

        setTransactionData({
          ...transactionData,
          showConfirm: true,
          attempting: false,
          hash: response.hash,
        })

        return {
          response,
          tokenId,
          operator,
        }
      })
      .catch((error) => {
        setTransactionData({
          ...transactionData,
          showConfirm: true,
          attempting: false,
          txError: error?.message ?? '',
        })
        logFailure(error)
        throw error
      })
  }, [approvalState, tokenId, positionManagerContract, operator, chainId, account, setTransactionData, transactionData])

  return [approvalState, approve]
}
