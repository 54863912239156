import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { PageName } from 'components/AmplitudeAnalytics/constants'
import { Trace } from 'components/AmplitudeAnalytics/Trace'
import { ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import PositionCalculatorWidget from 'components/PositionCalculatorWidget'
import Row, { RowBetween } from 'components/Row'
import StakingSettingsTab from 'components/StakingSettings'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import ThreeDotLoader from 'components/ThreeDotLoader'
import { MouseoverTooltip } from 'components/Tooltip'
import { STAKING_LP_TOKENS, STAKING_TOKENS } from 'constants/staking'
import { STAKING_V3_POOLS, TESTNET_STAKING_V3_POOLS } from 'constants/v3-staking'
import { useToken } from 'hooks/Tokens'
import { useHydraAccount, useHydraChainId } from 'hooks/useAddHydraAccExtension'
import useMobileWebViewCheck from 'hooks/useMobileWebViewCheck'
import { usePool } from 'hooks/usePools'
import { ChainId } from 'hydra/sdk'
import AppBody from 'pages/AppBody'
import { useContext, useMemo, useState } from 'react'
import { Link as HistoryLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useIncentiveConfig, useMidPosition, usePositionAPR } from 'state/incentive/hooks'
import { getIncentiveId } from 'state/incentive/utils'
import { useHydraStakingInfo, useStakingAPY, useStakingTokenConfig } from 'state/stake/hooks'
import styled, { ThemeContext } from 'styled-components/macro'

import { HeaderText } from './styleds'

const PageWrapper = styled(AutoColumn)`
  max-width: 540px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
    padding: 0px 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.deprecated_bg0};
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

const CalcWidgetWrapper = styled.div`
  background-color: ${({ theme }) => theme.deprecated_bg0};
  padding: 16px;
  border-radius: 20px;
  max-width: 480px;
`

interface StakingTokenItemProps {
  address: string
  name: string
  showLoader?: boolean
  showInactive?: boolean
}

function StakingTokenItem({ address, name, showLoader, showInactive = true }: StakingTokenItemProps) {
  const theme = useContext(ThemeContext)
  const token = useToken(address)

  const stakingConfig = useStakingTokenConfig(address)

  const { aprPercentage } = useStakingAPY(parseUnits('1', token?.decimals), token || undefined)

  if (!showInactive && stakingConfig && new Date().getTime() / 1000 >= Number(stakingConfig?.endTime)) {
    return null
  }

  return (
    <HistoryLink style={{ textDecoration: 'none' }} to={!showLoader ? `/stake/${address}` : ''}>
      <ButtonSecondary fontSize={'18px'} padding={'12px 16px'}>
        <RowBetween>
          <Text>{name}</Text>
          {showLoader ? (
            <ThreeDotLoader />
          ) : aprPercentage ? (
            <Text color={theme.textPrimary} fontSize={'14px'}>{`APR: ${aprPercentage}%`}</Text>
          ) : (
            <Text>{'>'}</Text>
          )}
        </RowBetween>
      </ButtonSecondary>
    </HistoryLink>
  )
}
interface StakingHydraItemProps {
  showLoader: boolean
}
function StakingHydraItem({ showLoader }: StakingHydraItemProps) {
  const theme = useContext(ThemeContext)
  const { apy } = useHydraStakingInfo()

  return (
    <HistoryLink style={{ textDecoration: 'none' }} to={!showLoader ? `/stake/hydra` : ''}>
      <ButtonSecondary fontSize={'18px'} padding={'12px 16px'}>
        <RowBetween>
          <Text>Hydra</Text>
          {showLoader ? (
            <ThreeDotLoader />
          ) : apy ? (
            <Text color={theme.textPrimary} fontSize={'14px'}>{`APR: ${apy}%`}</Text>
          ) : (
            <Text>{'>'}</Text>
          )}
        </RowBetween>
      </ButtonSecondary>
    </HistoryLink>
  )
}

interface StakingPoolItemProps {
  name: string
  incentiveId: string
  showInactive?: boolean
  calculatorValues?: { lower: string; upper: string } | null
}

function StakingPoolItem({ name, incentiveId, showInactive, calculatorValues }: StakingPoolItemProps) {
  const theme = useContext(ThemeContext)

  const incentiveConfig = useIncentiveConfig(incentiveId)

  const token0 = useToken(incentiveConfig?.tokenIdA)
  const token1 = useToken(incentiveConfig?.tokenIdB)

  const [, pool] = usePool(token0 ?? undefined, token1 ?? undefined, incentiveConfig?.fee)

  const [lower, upper] = useMemo(() => {
    if (calculatorValues) {
      const { lower, upper } = calculatorValues
      return [1 - Number(lower) / 100, 1 + Number(upper) / 100]
    }
    return [null, null]
  }, [calculatorValues])

  const pos = useMidPosition(pool, lower, upper)
  const apr = usePositionAPR(pos ?? undefined, incentiveId)

  if (!showInactive && incentiveConfig && new Date().getTime() / 1000 >= Number(incentiveConfig.key.endTime)) {
    return null
  }

  return (
    <HistoryLink style={{ textDecoration: 'none' }} to={`/stake/incentive/${incentiveId}`}>
      <ButtonSecondary fontSize={'18px'} padding={'12px 16px'}>
        <RowBetween>
          <Text>{name}</Text>
          {apr ? (
            <MouseoverTooltip text={<Trans>This value could vary depending on your position.</Trans>}>
              <Text color={theme.textPrimary} fontSize={'14px'}>{`APR: ${apr}%`}</Text>
            </MouseoverTooltip>
          ) : (
            <Text>{'>'}</Text>
          )}
        </RowBetween>
      </ButtonSecondary>
    </HistoryLink>
  )
}

export default function StakingList() {
  const isMobile = useMobileWebViewCheck()
  const [chainId] = useHydraChainId()
  const [account] = useHydraAccount()
  const [showInactive, setShowInactive] = useState(false)
  const [showCalculator, setShowCalculator] = useState(false)
  const [calculatorValues, setCalculatorValues] = useState({ lower: '10', upper: '10' })

  return (
    <Trace page={PageName.STAKING_LIST_PAGE} shouldLogImpression>
      {showCalculator && (
        <CalcWidgetWrapper>
          <PositionCalculatorWidget calculatorValues={calculatorValues} setCalculatorValues={setCalculatorValues} />
        </CalcWidgetWrapper>
      )}
      <AppBody>
        <PageWrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <MainContentWrapper>
                <Row justify="space-between" padding="0 0 16px 0">
                  <HeaderText>
                    <Trans>Available for Staking</Trans>
                  </HeaderText>

                  <StakingSettingsTab
                    showInactive={showInactive}
                    setShowInactive={setShowInactive}
                    showCalculator={showCalculator}
                    setShowCalculator={setShowCalculator}
                  />
                </Row>
                <AutoColumn gap="md">
                  <StakingHydraItem showLoader={isMobile && !account} />
                  {/* Tokens */}
                  {Object.keys(STAKING_TOKENS).map((key) => {
                    const {
                      stakingToken: { address },
                      name,
                    } = STAKING_TOKENS[key as keyof typeof STAKING_TOKENS]
                    return (
                      <StakingTokenItem key={key} address={address} name={name} showLoader={isMobile && !account} />
                    )
                  })}

                  {/* V3 Pools */}
                  {STAKING_V3_POOLS.map((data, i) => (
                    <StakingPoolItem
                      key={i}
                      name={data.name}
                      incentiveId={getIncentiveId(data.key)}
                      showInactive={showInactive}
                      calculatorValues={showCalculator ? calculatorValues : null}
                    />
                  ))}

                  {chainId === ChainId.TESTNET &&
                    TESTNET_STAKING_V3_POOLS.map((data, i) => (
                      <StakingPoolItem
                        key={i}
                        name={data.name}
                        incentiveId={getIncentiveId(data.key)}
                        showInactive={showInactive}
                        calculatorValues={showCalculator ? calculatorValues : null}
                      />
                    ))}

                  {/* V2 Pairs */}
                  {Object.keys(STAKING_LP_TOKENS).map((key) => {
                    const {
                      stakingToken: { address },
                      name,
                    } = STAKING_LP_TOKENS[key as keyof typeof STAKING_LP_TOKENS]
                    return (
                      <StakingTokenItem
                        key={key}
                        address={address}
                        name={name}
                        showLoader={isMobile && !account}
                        showInactive={showInactive}
                      />
                    )
                  })}
                </AutoColumn>
              </MainContentWrapper>
            </AutoColumn>
          </AutoColumn>
        </PageWrapper>
      </AppBody>
      <SwitchLocaleLink />
    </Trace>
  )
}
