import { useHydraChainId } from 'hooks/useAddHydraAccExtension'
import useDebounce from 'hooks/useDebounce'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'state/hooks'

import { EXPLORER_URLS } from '../../constants'
import { updateBlockNumber } from './reducer'

let interval: NodeJS.Timeout | undefined

export default function Updater(): null {
  const [chainId] = useHydraChainId()
  const dispatch = useAppDispatch()
  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number | null }>({
    chainId,
    blockNumber: null,
  })

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((state) => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== 'number') return { chainId, blockNumber }
          return { chainId, blockNumber: Math.max(blockNumber, state.blockNumber) }
        }
        return state
      })
    },
    [chainId, setState]
  )

  const fetchBlock = useCallback(() => {
    fetch(EXPLORER_URLS[chainId] + '/api/info')
      .then((r) => {
        r.json().then((info) => {
          blockNumberCallback(info.height)
        })
      })
      .catch((error) => console.error(`Failed to get block number for chainId: ${chainId}`, error))
  }, [blockNumberCallback, chainId])

  useEffect(() => {
    if (chainId && windowVisible) {
      if (interval) {
        clearInterval(interval)
        interval = undefined
      }

      setState({ chainId, blockNumber: null })
      fetchBlock()
      if (!interval) {
        interval = setInterval(() => fetchBlock(), 15000)
      }
    }
  }, [dispatch, chainId, windowVisible, blockNumberCallback, fetchBlock])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return
    dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }))
  }, [windowVisible, dispatch, debouncedState])

  return null
}
