export const UTXO = 'Min. UTXO size'
export const FEES = 'Fees'

export const SUPER_STAKER_TABLE_CONFIG: { [key: string]: any } = {
  name: { name: 'Staker', style: { textAlign: 'left' }, isSort: false },
  fees: { name: FEES, style: { textAlign: 'right' }, isSort: true },
  utxo: { name: UTXO, style: { textAlign: 'right' }, isSort: true },
  buttonAction: { name: '', style: { textAlign: 'right' }, isSort: false },
}

export const SORT_KEY_UTXO = 'utxo'
export const SORT_KEY_FEES = 'fees'
export const TESTNET = 'TestNet'
export const PENDING_TEXT = 'Please Wait..'

export const CONFIRM_UNDELEGATE_TEXT = `Are you sure you want to un-delegate your staking from`
export const CONFIRM_DELEGATE_TEXT = `Are you sure you want to delegate your staking to`

export const hydraMainNetURL = {
  api: 'https://explorer.hydrachain.org/api/tx/',
  explorer: 'https://explorer.hydrachain.org/tx/',
  contract: 'https://explorer.hydrachain.org/contract/',
}
export const hydraTestNetURL = {
  api: 'https://testexplorer.hydrachain.org/api/tx/',
  explorer: 'https://testexplorer.hydrachain.org/tx/',
  contract: 'https://testexplorer.hydrachain.org/contract/',
}

export const TOOLTIP_MESSAGE_FOR_SPLIT_UTXO =
  'Optimizing UTXOs is a single time operation for improving APR. Please note that once the optimization is executed, there will be 2000 blocks maturity before you begin to actively stake. Each optimization incurs transaction costs relative to the wallet size.'
