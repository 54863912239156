import styled, { keyframes } from 'styled-components/macro'

const LoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
`

interface DotProps {
  animation: string
}

const Dot = styled.div<DotProps>`
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.deprecated_bg4};
  animation: ${(props) => props.animation} 1.5s infinite ease-in-out;
`

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1);
  }
`

const Dot1 = styled(Dot)`
  left: 0;
  animation-delay: -0.32s;
  animation-name: ${bounce};
`

const Dot2 = styled(Dot)`
  left: 22px;
  animation-delay: -0.16s;
  animation-name: ${bounce};
`

const Dot3 = styled(Dot)`
  left: 44px;
  animation-delay: 0s;
  animation-name: ${bounce};
`

const ThreeDotLoader: React.FC = () => {
  return (
    <LoaderWrapper>
      <Dot1 animation={'bounce'} />
      <Dot2 animation={'bounce'} />
      <Dot3 animation={'bounce'} />
    </LoaderWrapper>
  )
}

export default ThreeDotLoader
