import { Currency, Percent } from '@uniswap/sdk-core'
import { ZERO_PERCENT } from 'constants/misc'
import { useStablecoinValue } from 'hooks/useStablecoinPrice'
import { TradeType } from 'hydra/sdk'
import { Trade } from 'hydra-v2-sdk'
import { useMemo } from 'react'
import { InterfaceTrade } from 'state/routing/types'

// returns whether tradeB is better than tradeA by at least a threshold percentage amount
export function isTradeBetter(
  tradeA: Trade<Currency, Currency, TradeType> | InterfaceTrade<Currency, Currency, TradeType> | undefined | null,
  tradeB: Trade<Currency, Currency, TradeType> | InterfaceTrade<Currency, Currency, TradeType> | undefined | null,
  minimumDelta: Percent = ZERO_PERCENT
): boolean | undefined {
  if (tradeA && !tradeB) return false
  if (tradeB && !tradeA) return true
  if (!tradeA || !tradeB) return undefined

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeB.outputAmount.currency.equals(tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable')
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice)
  } else {
    if (tradeA.tradeType === TradeType.EXACT_INPUT) {
      return tradeA.outputAmount.lessThan(tradeB.outputAmount)
    } else {
      return tradeA.inputAmount.greaterThan(tradeB.inputAmount)
    }
  }
}

// returns whether a split trade is better than a normal trade by at least a threshold percentage amount
// NOTE: Currently hardcoded to only work for HYDRA -> USDC/WBTC/ETH split
export function useIsSplitTradeBetter(
  splitTrade: InterfaceTrade<Currency, Currency, TradeType> | undefined | null,
  normalTrade: Trade<Currency, Currency, TradeType> | InterfaceTrade<Currency, Currency, TradeType> | undefined | null
) {
  const fiatValueNormal = useStablecoinValue(normalTrade?.outputAmount)
  const fiatValueSplitUSDC = useStablecoinValue(splitTrade?.outputAmount)
  const fiatValueSplitWBTC = useStablecoinValue(splitTrade?.extraTrades?.[0]?.outputAmount)
  const fiatValueSplitETH = useStablecoinValue(splitTrade?.extraTrades?.[1]?.outputAmount)

  const fiatValueSplit = useMemo(() => {
    if (fiatValueSplitUSDC && fiatValueSplitWBTC && fiatValueSplitETH) {
      return fiatValueSplitUSDC.add(fiatValueSplitWBTC).add(fiatValueSplitETH)
    }
    return null
  }, [fiatValueSplitUSDC, fiatValueSplitWBTC, fiatValueSplitETH])

  const result = useMemo(() => {
    if (fiatValueSplit && !fiatValueNormal) return true
    if (fiatValueNormal && !fiatValueSplit) return false
    if (!fiatValueNormal || !fiatValueSplit) return undefined

    return fiatValueNormal?.lessThan(fiatValueSplit)
  }, [fiatValueNormal, fiatValueSplit])

  return result
}
