// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { useHydraChainId } from 'hooks/useAddHydraAccExtension'
import { Dispatch, SetStateAction, useRef } from 'react'
import { Settings } from 'react-feather'
import { Text } from 'rebass'
import styled, { useTheme } from 'styled-components/macro'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useModalIsOpen, useToggleStakingSettingsMenu } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import Toggle from '../Toggle'

const StyledMenuIcon = styled(Settings)<{ redesignFlag: boolean }>`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme, redesignFlag }) => (redesignFlag ? theme.textSecondary : theme.deprecated_text1)};
  }
`

const StyledMenuButton = styled.button<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border-radius: 0.5rem;
  height: 20px;

  ${({ disabled }) =>
    !disabled &&
    `
    :hover,
    :focus {
      cursor: pointer;
      outline: none;
      opacity: 0.7;
    }
  `}
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span<{ redesignFlag: boolean }>`
  min-width: 20.125rem;
  background-color: ${({ theme, redesignFlag }) => (redesignFlag ? theme.backgroundSurface : theme.deprecated_bg2)};
  border: 1px solid ${({ theme, redesignFlag }) => (redesignFlag ? theme.backgroundOutline : theme.deprecated_bg3)};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: 0rem;
  z-index: 100;
  color: ${({ theme, redesignFlag }) => redesignFlag && theme.textPrimary};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};

  user-select: none;
`
export default function StakingSettingsTab({
  showInactive,
  setShowInactive,
  showCalculator,
  setShowCalculator,
}: {
  showInactive: boolean
  setShowInactive: Dispatch<SetStateAction<boolean>>
  showCalculator: boolean
  setShowCalculator: Dispatch<SetStateAction<boolean>>
}) {
  const [chainId] = useHydraChainId()
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled

  const node = useRef<HTMLDivElement>()
  const open = useModalIsOpen(ApplicationModal.STAKING_SETTINGS)
  const toggle = useToggleStakingSettingsMenu()

  const theme = useTheme()

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton
        disabled={!chainId}
        onClick={toggle}
        id="open-staking-settings-dialog-button"
        aria-label={t`Staking Settings`}
      >
        <StyledMenuIcon redesignFlag={redesignFlagEnabled} />
      </StyledMenuButton>
      {open && (
        <MenuFlyout redesignFlag={redesignFlagEnabled}>
          <AutoColumn gap="md" style={{ padding: '1rem' }}>
            <Text fontWeight={600} fontSize={14}>
              <Trans>Settings</Trans>
            </Text>
            <RowBetween>
              <RowFixed>
                <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} color={theme.deprecated_text2}>
                  <Trans>Show Inactive</Trans>
                </ThemedText.DeprecatedBlack>
              </RowFixed>

              <Toggle
                id="show-past-campaigns"
                isActive={showInactive}
                toggle={() => {
                  setShowInactive(!showInactive)
                }}
              />
            </RowBetween>

            <RowBetween>
              <RowFixed>
                <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} color={theme.deprecated_text2}>
                  <Trans>V3 APR Widget</Trans>
                </ThemedText.DeprecatedBlack>
              </RowFixed>

              <Toggle
                id="show-past-campaigns"
                isActive={showCalculator}
                toggle={() => {
                  setShowCalculator(!showCalculator)
                }}
              />
            </RowBetween>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
